/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
import Handlebars from "handlebars";
import React from "react";

export function regexPhoneNumber(str) {
	const regex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
	return regex.test(str.trim().toLowerCase());
}

export function regexMail(str) {
	/* eslint-disable-next-line */
	return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str.trim().toLowerCase());
}

export function generateRandomCode(length = 5) {
	let code = "";
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	for (let i = 0; i < length; i++) {
		code += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return code;
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(/[^\w-]+/g, "") // Remove all non-word chars
		.replace(/--+/g, "-") // Replace multiple - with single -
		.replace(/^-+/, "") // Trim - from start of text
		.replace(/-+$/, ""); // Trim - from
}

export function generateNameCode(text) {
	return `${slugify(text)}-${generateRandomCode()}`;
}

/**
 * Convert the color name to hex
 * @param {string} colorName - The color name
 * @returns {string} - The hex color
 */
export function getHexColor(colorName) {
	if (!colorName) return "#000000";
	// Create a temporary element to get the computed color
	const tempElement = document.createElement("div");
	tempElement.style.color = colorName; // Assign the color to the temporary element
	document.body.appendChild(tempElement);

	// Get the computed color
	const computedColor = window.getComputedStyle(tempElement).color;
	document.body.removeChild(tempElement); // Clean up

	// Convert the computed color to hex
	const rgb = computedColor.match(/\d+/g); // Extract the RGB components
	const hex = `#${rgb.map(x => parseInt(x).toString(16).padStart(2, "0")).join("")}`;

	return hex;
}

export function formatMillis(s, format = "full", maxDateUnit = null) {
	if (s === 0) return "0 ms";
	// Pad to 2 or 3 digits, default is 2
	function pad(n, z = 2) {
		if (n === 0) return "";
		// return ("   " + n).slice(-z).trim();
		return ("   " + String(n).padStart(2, "0")).trim();
	}

	let mapper = { ms: 0, s: 1, m: 2, h: 3, d: 4, y: 5 };

	let nbr = 7; // Max number of element in the resulting string
	if (format === "simple") nbr = 3;

	let ms = maxDateUnit === "ms" ? s : s % 1000;
	s = (s - ms) / 1000;

	let secs = maxDateUnit === "s" ? s : s % 60;
	s = (s - secs) / 60;

	let mins = maxDateUnit === "m" ? s : s % 60;
	s = (s - mins) / 60;

	let hrs = maxDateUnit === "h" ? s : s % 24;
	s = (s - hrs) / 24;

	let days = maxDateUnit === "d" ? s : s % 365;
	s = (s - days) / 365;

	let years = s % 365;

	let res = "";

	let val = pad(years);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.y)) {
		res += val + " y ";
		nbr--;
	}

	val = pad(days);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.d)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " d ";
	}

	val = pad(hrs);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.h)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " h ";
	}

	val = pad(mins);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.m)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " mins ";
	}

	if (format === "short") {
		if (res === "") {
			return "< 1 min";
		}
		return res;
	}

	val = pad(secs);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.s)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " s ";
	}

	val = pad(ms, 3);
	if (val !== "" && (!maxDateUnit || mapper[maxDateUnit] >= mapper.ms)) {
		if (res !== "") nbr--;
		if (nbr > 0) res += val + " ms";
	}

	return res.trim();
}

/**
 * Recursively removes keys with "undefined" values from an object, including nested objects.
 * @param {Object} obj - The input object to remove undefined keys from.
 */
function removeUndefinedKeys(obj) {
	for (const key in obj) {
		if (obj[key] === "undefined") {
			delete obj[key];
		} else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
			// Recursively check nested objects
			removeUndefinedKeys(obj[key]);
		}
	}
}

/**
 * replaceInText replace tags like this <?tag.example> by the value in the context
 * @param {String} templateModel contains the template with possibly fields to replace
 * @param {Object} context an object with all value for replacement
 * @param {Boolean} isJson is true if the text is a json, usefull to treat string (add "" in json, not elsewhere)
 */
export function replaceInTextHbr(templateModel, context, isJson = false) {
	try {
		const template = Handlebars.compile(templateModel);
		const result = template(context);
		if (isJson) {
			const obj = JSON.parse(result);
			removeUndefinedKeys(obj);
			return obj;
		}
		return result;
	} catch (error) {
		return null;
	}
}
