import { useState } from "react";
import { Divider, Grid } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import ChartEditingOrder from "../Components/ChartEditingOrder";
import ProportionChart from ".";

/**
 * Display proportion charts
 * @param {*} param0
 * @returns
 */
const ProportionChartDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart,
	clickHandler = () => {}
}) => {
	const LIST = ["proportion", "oneDimension", "list", "range"];

	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<>
			<MDBox display="flex" flexDirection="row">
				{pageEditMode && (
					<ChartEditingOrder
						getChartsData={getChartsData}
						type={LIST}
						dragHandler={dragHandler}
						handleEditChart={handleEditChart}
						mouseIn={editingMouseInCard}
						mouseOut={editingMouseOutCard}
						handleDeleteChart={handleDeleteChart}
					/>
				)}
				<MDBox
					flex="5"
					style={{
						width: "100%",
						overflowX: "auto"
					}}
				>
					<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
						{getChartsData(LIST).map((chart, index) => {
							return (
								<ProportionChart
									id={chart.code}
									key={index}
									chart={chart}
									data={chart.data}
									mt={5}
									xs={2}
									md={4}
									lg={2}
									xxl={chart.display?.width ?? 3}
									style={{
										filter: isHovered(chart.code) ? "brightness(0.8)" : ""
									}}
									clickHandler={clickHandler}
								/>
							);
						})}
					</Grid>
				</MDBox>
			</MDBox>
			{pageEditMode && getChartsData(LIST).length > 0 && <Divider mt={10} mb={10} />}
		</>
	);
};

export default ProportionChartDisplay;
