import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";

const LimitField = ({ chart, handleChange, dictionary }) => {
	return (
		<MDBox mt={1}>
			<MDInput
				value={chart.request.limit}
				type="number"
				className="dialogInput"
				label={t("SETTINGS.CHARTS.NEW.displayLimit")}
				InputProps={{ inputProps: { min: 1 } }}
				onChange={e => {
					if (e.target.value < 1) e.target.value = null;
					handleChange("request.limit", e.target.value);
				}}
			/>
		</MDBox>
	);
};

export default LimitField;
