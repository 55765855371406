import lod_ from "lodash";
import { t } from "i18next";
import FieldInput from "./FieldInput";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import C from "../../../constants";
import { parseTypeDictionary } from "helpers/dictionary";

const BinningComponent = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};
	const binning = chart?.request?.binning ?? [];

	return (
		<MDBox>
			<FieldInput
				required
				chart={chart}
				handleChange={handleChange}
				info={t("SETTINGS.CHARTS.NEW.dateAttributeSelectionInfo")}
				disabled={!request.computeMethod}
				title={t("SETTINGS.CHARTS.NEW.dateAttributeSelection")}
				field="dateAttribute"
				dictionary={parseTypeDictionary(["datetime", "date"], dictionary)}
			/>
			<MDBox mt={2}>
				<MDBox display="flex" alignItems="center">
					<MDTypography variant="h6">
						{t("SETTINGS.CHARTS.NEW.binningSelection")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={t("SETTINGS.CHARTS.NEW.binningSelectionInfo")} placement="top">
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				</MDBox>
				<BinningInput binning={binning} handleChange={handleChange} />
			</MDBox>
		</MDBox>
	);
};

const BinningInput = ({ binning, handleChange }) => {
	const [binningMenuEl, setBinningMenuEl] = useState(null);

	const matchingBinning = C.BINNING_LIST.find(b => lod_.isEqual(b.binning, binning));

	return (
		<>
			<MDBox
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="stretch"
				style={{ cursor: "pointer" }}
				onClick={e => setBinningMenuEl(e.target)}
			>
				<MDBox
					flex="1"
					className="boxInputStyle"
					borderRadius="md"
					style={{
						borderRight: "none",
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				>
					{matchingBinning ? (
						<MDTypography variant="body1" fontSize="small">
							{matchingBinning.name}
						</MDTypography>
					) : (
						<MDTypography variant="body1" fontSize="small" style={{ opacity: 0.7 }}>
							{t("SETTINGS.select")}
						</MDTypography>
					)}
				</MDBox>
				{/* Open dictionary menu */}
				<MDBox
					className="endButtonboxInputStyle"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Icon fontSize="small">keyboard_arrow_down</Icon>
				</MDBox>
			</MDBox>
			{/* Menu */}
			<Menu
				open={Boolean(binningMenuEl)}
				anchorEl={binningMenuEl}
				onClose={() => setBinningMenuEl(null)}
			>
				{C.BINNING_LIST.map((binning, index) => {
					return (
						<MenuItem
							key={index}
							onClick={() => {
								handleChange("request.binning", binning.binning);
								setBinningMenuEl(null);
							}}
						>
							{binning.name}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default BinningComponent;
