import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

const ComparisonTopFlopTableDisplay = ({ chart, data }) => {
	const top = chart.request.limit ?? 3;

	return (
		<MDBox m={2} display="flex" flexDirection="row" justifyContent="space-between">
			<MDBox flex="1" mr={1}>
				<MDTypography variant="h6">{`Top ${top}`}</MDTypography>
				{(data?.top ?? []).map((item, index) => {
					return (
						<MDBox
							key={index}
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							bgColor="light"
							borderRadius="md"
							p={1}
							mb={1}
						>
							<MDTypography>{item.label}</MDTypography>
							<MDTypography color="success" fontWeight="bold">
								+{item.diff}%
							</MDTypography>
						</MDBox>
					);
				})}
			</MDBox>
			<MDBox flex="1" ml={1}>
				<MDTypography variant="h6">{`Flop ${top}`}</MDTypography>
				{(data?.flop ?? []).map((item, index) => {
					return (
						<MDBox
							key={index}
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							bgColor="light"
							borderRadius="md"
							p={1}
							mb={1}
						>
							<MDTypography>{item.label}</MDTypography>
							<MDTypography color="error" fontWeight="bold">
								{item.diff}%
							</MDTypography>
						</MDBox>
					);
				})}
			</MDBox>
		</MDBox>
	);
};

export default ComparisonTopFlopTableDisplay;
