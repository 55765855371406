/* eslint-disable prefer-destructuring */
import lod_ from "lodash";
import axios from "axios";
import { Autocomplete, Checkbox, Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { getLabelFromDictionary } from "helpers/dictionary";
import { updateRequest } from "helpers/chartEditor";

const RankingComponent = ({ chart, dictionary, handleChange }) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);

	const display = chart?.display ?? {};
	const request = chart?.request ?? {};

	// anchors
	const [anchorRatingElement, setAnchorRatingElement] = useState(null);
	const [anchorZoneElement, setAnchorZoneElement] = useState(null);

	const [referenceValues, setReferenceValues] = useState([]);

	/**
	 * Reload reference values for ranking chart
	 * @param {*} e
	 * @returns
	 */
	const reloadReferenceValues = async e => {
		if (!request?.collection || !request?.reference?.target) return;

		let config = {
			method: "POST",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_AMBACK}/api/v1/ressource/${request.collection}/dynamic?search=${e}&page=1&limit=10`,
			data: {
				/* filter: actionFind?.filter, */ searchFields: [request.reference.target],
				attribute: request.reference.target
			},
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: profile.assistantID,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});

		let results = result.results;
		results = results.map(r => {
			return lod_.get(r, request.reference.target);
		});
		// Filter multiple values
		results = lod_.uniq(results);

		setReferenceValues(results);
		// return result?.results || [];
	};

	return (
		<MDBox>
			<MDBox mt={2}>
				{/* Title */}
				<MDTypography variant="h6">
					{t("CHARTS.RANKING.referenceField")}
					<span className="mandatoryField">*</span>
				</MDTypography>
				{/* Selection */}
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
				>
					{/* Input dictionary */}
					<MDBox
						flex="1"
						className="boxInputStyle"
						borderRadius="md"
						style={{
							borderRadius: "0.375rem 0 0 0.375rem"
						}}
					>
						{getLabelFromDictionary(dictionary[request?.collection], request?.reference?.target)}
					</MDBox>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => {
							if (!request.computeMethod) return;
							setAnchorRatingElement(e.currentTarget);
						}}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
					{/* Input value */}
					<Autocomplete
						disabled={!request.computeMethod || !request?.reference?.target}
						sx={{ flex: 1, ml: 1 }}
						id="reference-value"
						name="reference-value"
						options={referenceValues}
						onChange={(e, newValue) => {
							updateRequest(chart, handleChange, dictionary, "request.reference.value", newValue);
						}}
						defaultValue={request?.reference?.value}
						renderInput={params => (
							<MDInput
								{...params}
								label={t("CHARTS.RANKING.optionnalValue")}
								onChange={e => reloadReferenceValues(e.target.value)}
							/>
						)}
					/>

					<DictionaryMenu
						placement="right"
						dictionary={dictionary}
						anchorEl={anchorRatingElement}
						handleInsertText={e => {
							setAnchorRatingElement(null);
							updateRequest(chart, handleChange, dictionary, "request.reference.target", e);
							reloadReferenceValues();
						}}
						handleClose={() => setAnchorRatingElement(null)}
					/>
				</MDBox>
			</MDBox>
			<MDBox mt={2}>
				{/* Title */}
				<MDTypography variant="h6">{t("CHARTS.RANKING.complementaryDatas")}</MDTypography>

				<MDTypography variant="caption">{t("CHARTS.RANKING.displayValues")}</MDTypography>
				<MDBox
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
				>
					{/* Min */}
					<MDBox
						flex="1"
						p={0.5}
						mr={0.5}
						display="flex"
						flexDirection="row"
						alignItems="center"
						borderRadius="md"
						style={{
							border: "1px solid #e0e0e0",
							cursor: "pointer",
							userSelect: "none"
						}}
						onClick={() => {
							handleChange("display.extra.min.active", !display.extra?.min?.active);
						}}
					>
						<Checkbox checked={Boolean(display.extra?.min?.active)} />
						<MDTypography variant="button">{t("CHARTS.RANKING.global")}</MDTypography>
					</MDBox>
					{/* Max */}
					<MDBox
						flex="1"
						p={0.5}
						display="flex"
						flexDirection="row"
						alignItems="center"
						borderRadius="md"
						style={{
							border: "1px solid #e0e0e0",
							cursor: "pointer",
							userSelect: "none"
						}}
						onClick={() => {
							handleChange("display.extra.max.active", !display.extra?.max?.active);
						}}
					>
						<Checkbox checked={Boolean(display.extra?.max?.active)} />
						<MDTypography variant="button">{t("SETTINGS.CHARTS.COMPUTE.METHODS.max")}</MDTypography>
					</MDBox>
					{/* Global */}
					<MDBox
						flex="1"
						p={0.5}
						ml={0.5}
						display="flex"
						flexDirection="row"
						alignItems="center"
						borderRadius="md"
						style={{
							border: "1px solid #e0e0e0",
							cursor: "pointer",
							userSelect: "none"
						}}
						onClick={() => {
							handleChange("display.extra.global.active", !display.extra?.global?.active);
						}}
					>
						<Checkbox checked={Boolean(display.extra?.global?.active)} />
						<MDTypography variant="button">{t("SETTINGS.CHARTS.COMPUTE.METHODS.min")}</MDTypography>
					</MDBox>
				</MDBox>
				{/* New zone */}
				<MDBox>
					{/* Title */}
					<MDTypography variant="caption">{t("CHARTS.RANKING.addZone")}</MDTypography>
					{/* Selection */}
					<MDBox
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="stretch"
					>
						{/* Input dictionary */}
						<MDBox
							flex="1"
							className="boxInputStyle"
							borderRadius="md"
							style={{
								borderRadius: "0.375rem 0 0 0.375rem"
							}}
						>
							{getLabelFromDictionary(dictionary[request.collection], request?.reference?.zone)}
						</MDBox>
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="light"
							onClick={e => {
								updateRequest(chart, handleChange, dictionary, "request.reference.zone", null);
							}}
							style={{
								borderRadius: "0"
							}}
						>
							<Icon fontSize="small">close</Icon>
						</MDBox>
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="light"
							onClick={e => {
								if (!request.computeMethod) return;
								setAnchorZoneElement(e.currentTarget);
							}}
						>
							<Icon fontSize="small">menu</Icon>
						</MDBox>
						{/* Input value */}
						<DictionaryMenu
							placement="right"
							dictionary={dictionary}
							anchorEl={anchorZoneElement}
							handleInsertText={e => {
								setAnchorZoneElement(null);
								updateRequest(chart, handleChange, dictionary, "request.reference.zone", e);
							}}
							handleClose={() => setAnchorZoneElement(null)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default RankingComponent;
