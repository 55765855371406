import { t } from "i18next";
import FieldInput from "./FieldInput";
import { parseTypeDictionary } from "helpers/dictionary";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import RatioSubsetComponent from "./RatioSubsetComponent";

const AttributeField = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};

	if (request.computeMethod === "RATIO") {
		return (
			<MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="center">
				<MDBox flex="1">
					<FieldInput
						required
						chart={chart}
						handleChange={handleChange}
						disabled={!request.computeMethod}
						title={t("SETTINGS.CHARTS.NEW.numerator")}
						field="attribute"
						dictionary={parseTypeDictionary(["timestamp", "number"], dictionary)}
					/>
				</MDBox>
				<MDTypography variant="h6" sx={{ ml: 2, mr: 2 }}>
					/
				</MDTypography>
				<MDBox flex="1">
					<FieldInput
						required
						chart={chart}
						handleChange={handleChange}
						disabled={!request.computeMethod}
						title={t("SETTINGS.CHARTS.NEW.denominator")}
						field="ratioAttribute"
						dictionary={parseTypeDictionary(["timestamp", "number"], dictionary)}
					/>
				</MDBox>
			</MDBox>
		);
	}

	if (request.computeMethod === "RATIO_SUBSET") {
		return (
			<RatioSubsetComponent chart={chart} dictionary={dictionary} handleChange={handleChange} />
		);
	}

	if (request.computeMethod === "COUNT" && chart.type !== "range") {
		return null;
	}

	if (chart.type === "range") {
		return (
			<FieldInput
				required
				chart={chart}
				handleChange={handleChange}
				info={t("SETTINGS.CHARTS.NEW.computeAttributeInfo")}
				disabled={!request.computeMethod}
				title={t("SETTINGS.CHARTS.NEW.computeAttribute")}
				field="attribute"
				dictionary={parseTypeDictionary(["timestamp", "number"], dictionary)}
			/>
		);
	}

	return (
		<FieldInput
			required
			chart={chart}
			handleChange={handleChange}
			info={t("SETTINGS.CHARTS.NEW.computeAttributeInfo")}
			disabled={!request.computeMethod}
			title={t("SETTINGS.CHARTS.NEW.computeAttribute")}
			field="attribute"
			dictionary={dictionary}
		/>
	);
};

export default AttributeField;
