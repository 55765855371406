/* eslint-disable react-hooks/rules-of-hooks */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import lod_ from "lodash";
import { useMemo, useEffect, useState } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import MDPagination from "components/Basics/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "components/Advanced/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Advanced/Tables/DataTable/DataTableBodyCell";
import { t } from "i18next";

function DataTable({
	fixedList = false,
	entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch = false,
	showTotalEntries = true,
	table = {},
	pagination = { variant: "gradient", color: "info" },
	isSorted = true,
	noEndBorder = false,
	handleChangeEntriesPerPage = null
}) {
	if (!table?.columns || !table.rows) return null;

	/**
	 * Default pagination default value
	 * @returns {number} default value
	 */
	const getDefaultValue = () => {
		if (!lod_.isNil(entriesPerPage.defaultValue)) {
			return entriesPerPage.defaultValue;
		}
		if (!lod_.isNil(entriesPerPage.defaultEntrie)) {
			return entriesPerPage.defaultEntrie;
		}
		return 10;
	};

	/**
	 * Get pagination entries
	 * @returns {Array<number>} entries
	 */
	const defaultEntries = () => {
		if (!lod_.isNil(entriesPerPage.entries)) {
			return entriesPerPage.entries;
		}
		if (!lod_.isNil(entriesPerPage.entriesPerPage)) {
			return entriesPerPage.entriesPerPage;
		}
		return [5, 10, 15, 20, 25];
	};

	const defaultValue = getDefaultValue();
	const entries = defaultEntries();

	const columns = useMemo(() => table.columns, [table]);
	const data = useMemo(() => table.rows, [table]);
	const count = table.count ?? table.rows.length;

	const totalRow = useMemo(() => table.totalRow, [table]);

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter }
	} = tableInstance;

	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = value => setPageSize(value);

	// Render the paginations
	const renderPagination = pageOptions.map(option => (
		<MDPagination
			item
			key={option}
			onClick={() => gotoPage(Number(option))}
			active={pageIndex === option}
		>
			{option + 1}
		</MDPagination>
	));

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = column => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = pageSize;
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	/**
	 * Display total line at the end of the table
	 */
	function getTotalRow() {
		if (totalRow) {
			return (
				<TableRow>
					{Object.keys(totalRow).map((key, index) => {
						let cell = table.columns.find(el => el.accessor === key);
						if (!cell) return null;
						return (
							<DataTableBodyCell className="totalCell" key={index}>
								{totalRow[key] ?? 0}
							</DataTableBodyCell>
						);
					})}
				</TableRow>
			);
		} else {
			return null;
		}
	}

	function hasComparaison(cell) {
		if (cell.column.hasComparaison) {
			return "right";
		} else if (cell.column.align) {
			return cell.column.align;
		} else {
			return "left";
		}
	}

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{entriesPerPage || canSearch ? (
				<MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
					{!fixedList && entriesPerPage && (
						<MDBox display="flex" alignItems="center">
							<Autocomplete
								disableClearable
								value={pageSize.toString()}
								options={entries}
								onChange={(event, newValue) => {
									if (handleChangeEntriesPerPage) handleChangeEntriesPerPage(newValue);
									setEntriesPerPage(parseInt(newValue, 10));
								}}
								size="small"
								sx={{ width: "5rem" }}
								renderInput={params => <MDInput {...params} />}
							/>
							<MDTypography variant="caption" color="secondary">
								&nbsp;&nbsp;{t("DATATABLE.entriesPerPage")}
							</MDTypography>
						</MDBox>
					)}
					{canSearch && (
						<MDBox /* width="12rem" ml="auto" */ flex="1" sx={{ ml: 5 }}>
							<MDInput
								placeholder={t("DATATABLE.search")}
								value={search}
								size="small"
								fullWidth
								onChange={({ currentTarget }) => {
									setSearch(search);
									onSearchChange(currentTarget.value);
								}}
							/>
						</MDBox>
					)}
				</MDBox>
			) : null}
			<Table {...getTableProps()}>
				<MDBox component="thead">
					{headerGroups.map((headerGroup, groupIndex) => (
						<TableRow {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
							{headerGroup.headers.map((column, headerIndex) => {
								if (column.hidden) {
									return null;
									// return (
									// 	<DataTableHeadCell
									// 		{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
									// 		width={column.width ? column.width : "auto"}
									// 		align={column.align ? column.align : "left"}
									// 		sorted={setSortedValue(column)}
									// 		hidden
									// 	></DataTableHeadCell>
									// );
								}
								return (
									<DataTableHeadCell
										{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
										width={column.width ? column.width : "auto"}
										align={column.align ? column.align : "left"}
										sorted={setSortedValue(column)}
										className={column.id === "row_total" ? "totalCell" : ""}
										colspan={column.colspan ?? "1"}
										key={headerIndex}
									>
										{column.render("Header")}
									</DataTableHeadCell>
								);
							})}
						</TableRow>
					))}
				</MDBox>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, key) => {
						prepareRow(row);
						let rowKeys = Object.keys(row.original);
						return (
							<TableRow {...row.getRowProps()} key={key}>
								{row.cells.map((cell, cellKey) => {
									let className = "";

									if (cell.column.id === "row_total") {
										className += "totalCell";
									}

									if (cell.column.hasComparaison) {
										let comparation = row.cells[cellKey + 1];
										let basicValue = cell.value ?? 0;
										let basicComparationValue = comparation.value ?? 0;
										let value = parseFloat(basicValue.toString().replace("%", ""));
										let comparationValue = parseFloat(
											basicComparationValue.toString().replace("%", "")
										);

										// if (value > comparationValue) {
										// 	className += " ratioRed";
										// } else if (value < comparationValue) {
										// 	className += " ratioGreen";
										// }
									}

									return (
										<DataTableBodyCell
											noBorder={noEndBorder && rows.length - 1 === key}
											align={hasComparaison(cell)}
											className={className}
											{...cell.getCellProps()}
											key={cellKey}
										>
											{cell?.value ? cell.render("Cell") : 0}
										</DataTableBodyCell>
									);
								})}
							</TableRow>
						);
					})}
					{getTotalRow()}
				</TableBody>
			</Table>

			<MDBox
				display="flex"
				flexDirection={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				alignItems={{ xs: "flex-start", sm: "center" }}
				p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
			>
				{!fixedList && showTotalEntries && (
					<MDBox mb={{ xs: 3, sm: 0 }}>
						<MDTypography variant="button" color="secondary" fontWeight="regular">
							{`${t("DATATABLE.showing")} ${entriesStart} ${t("DATATABLE.to")} ${
								count < entriesEnd ? count : entriesEnd
							} ${t("DATATABLE.entries")} (${count} ${t("DATATABLE.entries")})`}
						</MDTypography>
					</MDBox>
				)}
				{!fixedList && pageOptions.length > 1 && (
					<MDPagination
						variant={pagination.variant ? pagination.variant : "gradient"}
						color={pagination.color ? pagination.color : "info"}
					>
						{canPreviousPage && (
							<MDPagination item onClick={() => previousPage()}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
							</MDPagination>
						)}
						{renderPagination.length > 6 ? (
							<MDBox width="5rem" mx={1}>
								<MDInput
									inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
									value={customizedPageOptions[pageIndex]}
									onChange={(handleInputPagination, handleInputPaginationValue)}
								/>
							</MDBox>
						) : (
							renderPagination
						)}
						{canNextPage && (
							<MDPagination item onClick={() => nextPage()}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
							</MDPagination>
						)}
					</MDPagination>
				)}
			</MDBox>
		</TableContainer>
	);
}

export default DataTable;
