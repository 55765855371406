/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { Icon, IconButton, Tooltip } from "@mui/material";
import MethodSelect from "./Filter/MethodSelect";
import ValueSelect from "./Filter/ValueSelect";
import PathSelect from "./Filter/PathSelect";

/**
 * Row for the filters table
 * @returns
 */
const FilterTableLine = ({
	disabled = false,
	name,
	path,
	value,
	method,
	filter,
	methods,
	handleRemoveFilter,
	onChangeValue,
	dictionary
}) => {
	const [validFilter, setValidFilter] = useState(false);

	const valueExist = v => {
		return v !== undefined && v !== null && v !== "";
	};

	const onChangeHandle = (newMethod, newValue) => {
		onChangeValue({
			name,
			path,
			filter,
			method: newMethod,
			value: newValue
		});

		setValidFilter(newMethod && valueExist(newValue));
	};

	const initDefaultValues = () => {
		// default values
		switch (filter?.type) {
			case "timestamp":
				// set default value to 01:00
				onChangeHandle(method, 1 * 60 * 60 + 0 * 60);
				break;
			case "boolean":
				onChangeHandle("$eq", Boolean(value));
				break;
			default:
				onChangeHandle(method, value);
				break;
		}
	};

	useEffect(() => {
		initDefaultValues();
	}, []);

	return (
		<MDBox mb={2} display="flex" flexDirection="row" alignItems="stretch" style={{ width: "100%" }}>
			{/* Path */}
			<PathSelect
				disabled={disabled}
				validFilter={validFilter}
				path={path}
				dictionary={dictionary}
			/>
			{/* Method */}
			<MethodSelect
				disabled={disabled}
				filter={filter}
				selectedMethod={method}
				methods={methods}
				setSelectedMethod={newMethod => {
					onChangeHandle(newMethod, value);
				}}
			/>
			{/* Value */}
			<ValueSelect
				disabled={disabled}
				filter={filter}
				name={name}
				selectedValue={value}
				selectedMethod={method}
				setSelectedValue={newValue => {
					onChangeHandle(method, newValue);
				}}
			/>
			{/* Delete */}
			{!disabled && (
				<IconButton onClick={() => handleRemoveFilter(name, filter.fullPath)}>
					<Tooltip title={i18n.t("SETTINGS.delete")} placement="top" arrow>
						<Icon>delete</Icon>
					</Tooltip>
				</IconButton>
			)}
		</MDBox>
	);
};

export default FilterTableLine;
