import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

const PreviousPeriodField = ({ chart, dictionary, handleChange }) => {
	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={() => handleChange("request.previousPeriod", !chart.request.previousPeriod)}
		>
			<span>{t("CHARTS.periodComparison")}</span>
			<MDBox>
				<Switch checked={chart.request.previousPeriod} />
			</MDBox>
		</MDBox>
	);
};

export default PreviousPeriodField;
