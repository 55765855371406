import lod_ from "lodash";
import { t } from "i18next";

import { Drawer, Icon, MenuItem, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { useDispatch, useSelector } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import { replaceInTextHbr } from "helpers/utilities";

/**
 * Dataset pannel view component
 * @param {*} param0 - open, onClose, data
 * @param {boolean} param0.open - open state
 * @param {function} param0.onClose - close function
 * @param {object} param0.data - data to display
 * @returns {JSX.Element}
 */
const DatasetViewPannel = ({ open, onClose, data = {} }) => {
	const emptyData = lod_.isNil(data) || lod_.isEmpty(data);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			style={{
				zIndex: 1300
			}}
			PaperProps={{
				sx: { width: "50%" }
			}}
		>
			{!emptyData && (
				<PannelContent
					chart={data.chart}
					dataset={data.dataset ?? {}}
					filters={data.filters ?? {}}
				/>
			)}
		</Drawer>
	);
};

/**
 * Pannel content component
 * @param {*} param0 - dataset, chart, filters
 * @param {object} param0.dataset - dataset object
 * @param {object} param0.chart - chart object
 * @param {object} param0.filters - filters object
 * @returns {JSX.Element} - Pannel content
 */
const PannelContent = ({ dataset, chart, filters }) => {
	const profile = useSelector(state => state.profile);

	const dispatch = useDispatch();

	const urlTemplate = chart.options?.url ?? null;

	return (
		<MDBox m={2}>
			{/* Header */}
			<MDBox display="flex" flexDirection="row" justifyContent="space-between">
				<MDTypography variant="h5" mb={2}>
					{dataset.label}
				</MDTypography>
				<MDTypography variant="subtitle2" mb={2}>
					{chart.data?.count ?? 0} {t("SETTINGS.results")}
				</MDTypography>
			</MDBox>
			{/* Content */}

			<DefaultDataTable
				noMargin
				noHeader
				list={chart}
				canSearch
				table={chart.data}
				display={chart.request.attributesDisplay}
				filter={filters}
				actions={[
					<MenuItem
						handleclick={({ values }, e) => {
							if (lod_.isNil(urlTemplate)) {
								dispatch(
									display({
										type: "error",
										message: t("CHARTS.DATASET.cannotFindURL")
									})
								);
								return;
							}

							const url = replaceInTextHbr(urlTemplate, {
								...values,
								assistantID: profile.assistantID
							});
							window.open(url, "_blank");
						}}
					>
						<Tooltip title={t("SETTINGS.see")} placement="top" arrow>
							<Icon fontSize="small">visibility</Icon>
						</Tooltip>
					</MenuItem>
				]}
			/>
		</MDBox>
	);
};

export default DatasetViewPannel;
