import { useEffect, useState } from "react";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import IconAccordion from "../../../components/IconAccordion";
import { t } from "i18next";
import { Icon, IconButton } from "@mui/material";
import C from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { getIconsList } from "components/Custom/IconsList";

const IconComponent = ({ chart, handleChange }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const [iconsList, setIconsList] = useState([]);

	/**
	 * Load icons
	 */
	async function loadIcons() {
		setIconsList(await getIconsList(dispatch, profile.assistantID));
	}

	useEffect(() => {
		// Load icons
		loadIcons();
	}, []);

	return (
		<MDBox mt={2}>
			<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.icon")}</MDTypography>
			<MDBox display="flex" alignItems="flex-stretch " flexDirection="column">
				{/* Color choice */}
				<IconAccordion
					title={
						<MDBox display="flex" alignItems="center">
							<MDBox
								mr={1}
								bgColor={chart.display.icon.color}
								variant="gradient"
								coloredShadow={chart.display.icon.color}
								borderRadius="xl"
								className="previewColorPickerContainer"
							></MDBox>
							<MDTypography variant="body1" fontSize="small">
								{t("SETTINGS.CHARTS.NEW.iconAvailableColors")}
							</MDTypography>
						</MDBox>
					}
					content={
						<MDBox display="flex" flexWrap="wrap">
							{C.ICONS_COLORS.map((color, index) => {
								return (
									<MDBox
										key={index}
										mr={1}
										bgColor={color}
										variant="gradient"
										coloredShadow={color}
										borderRadius="xl"
										className="colorPickerContainer"
										onClick={() => {
											handleChange("display.icon.color", color);
										}}
									>
										<Icon fontSize="small">{chart.display.icon.component}</Icon>
									</MDBox>
								);
							})}
						</MDBox>
					}
				/>
				{/* Icon choice */}
				<IconAccordion
					title={
						<MDBox display="flex" alignItems="center">
							<MDBox mr={1} display="flex" alignItems="center">
								<Icon fontSize="medium">{chart.display.icon.component}</Icon>
							</MDBox>
							<MDTypography variant="body1" fontSize="small">
								{t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
							</MDTypography>
						</MDBox>
					}
					content={iconsList.map((icon, index) => {
						return (
							<IconButton
								key={index}
								onClick={() => {
									let newIcon = {
										component: icon.code,
										color: chart.display.icon?.color || "info"
									};
									handleChange("display.icon", newIcon);
								}}
							>
								<Icon
									color={
										chart.display.icon.component === icon.code
											? chart.display.icon?.color || "info"
											: ""
									}
									fontSize="large"
								>
									{icon.code}
								</Icon>
							</IconButton>
						);
					})}
				/>
			</MDBox>
		</MDBox>
	);
};

export default IconComponent;
