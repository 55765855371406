import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

const TopLimitComponent = ({ chart, handleChange, dictionary }) => {
	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
		>
			<span>{t("CHARTS.limit")}</span>
			<MDBox>
				<FormControl>
					<RadioGroup
						row
						aria-labelledby="radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={chart.request.limit || "1"}
						onChange={e => handleChange("request.limit", e.target.value)}
					>
						<FormControlLabel value="1" control={<Radio />} label="1" />
						<FormControlLabel value="3" control={<Radio />} label="3" />
						<FormControlLabel value="5" control={<Radio />} label="5" />
						<FormControlLabel value="10" control={<Radio />} label="10" />
						<FormControlLabel value="20" control={<Radio />} label="20" />
						<FormControlLabel value="50" control={<Radio />} label="50" />
						<FormControlLabel value="null" control={<Radio />} label={t("CHARTS.all")} />
					</RadioGroup>
				</FormControl>
			</MDBox>
		</MDBox>
	);
};

export default TopLimitComponent;
