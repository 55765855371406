import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";

const LabelField = ({ chart, handleChange, dictionary }) => {
	return (
		<MDBox mt={1}>
			<MDInput
				value={chart.display.label || ""}
				className="dialogInput"
				label={t("SETTINGS.CHARTS.NEW.displayLabel")}
				onChange={e => handleChange("display.label", e.target.value)}
			/>
		</MDBox>
	);
};

export default LabelField;
