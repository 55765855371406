import MDBox from "components/Basics/MDBox";

import AttributeField from "./data/AttributeField";
import FilterDateField from "./data/FilterDateField";
import RankingComponent from "./data/RankingComponent";
import GroupField from "./data/GroupField";
import ColField from "./data/ColField";
import BinningComponent from "./data/BinningComponent";
import RowField from "./data/RowField";
import RangeComponent from "./data/RangeComponent";
import ComputeMethodField from "./data/ComputeMethodField";

/**
 * Datas tab
 * @param {*} param0
 * @returns {JSX.Element} - Datas tab
 */
const TechnicalDatasDisplay = ({ chart, dictionary, handleChange, modules }) => {
	const MODULE_MAPPING = {
		attributeField: AttributeField,
		filterDateField: FilterDateField,
		rankingTargetComponent: RankingComponent,
		groupField: GroupField,
		columnField: ColField,
		binningComponent: BinningComponent,
		rowField: RowField,
		computeMethodField: ComputeMethodField,
		valuesRangeComponent: RangeComponent
	};

	const DEFAULT_MODULES = ["computeMethodField"];

	const FINALE_MODULES = [...DEFAULT_MODULES, ...modules.data];

	return (
		<MDBox mt={2}>
			{FINALE_MODULES.map((module, index) => {
				const ModuleComponent = MODULE_MAPPING[module];

				if (ModuleComponent) {
					return <ModuleComponent key={index} {...{ chart, dictionary, handleChange }} />;
				} else {
					return null;
				}
			})}
		</MDBox>
	);
};

export default TechnicalDatasDisplay;
