import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

const DisplayLegendField = ({ chart, handleChange, dictionary }) => {
	const handleSwitchLegend = () => {
		handleChange("options.legend", !chart.options.legend);
	};

	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={() => handleSwitchLegend()}
		>
			<span>{t("CHARTS.displayLegend")}</span>
			<MDBox>
				<Switch checked={chart.options.legend ?? true} />
			</MDBox>
		</MDBox>
	);
};

export default DisplayLegendField;
