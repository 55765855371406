import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";

const TitleField = ({ chart, handleChange }) => {
	return (
		<>
			<MDTypography mt={2} variant="h6">
				{t("SETTINGS.CHARTS.NEW.informations")}
			</MDTypography>
			<MDBox mt={1}>
				<MDInput
					className="dialogInput"
					label={
						<span>
							{t("SETTINGS.CHARTS.NEW.chartTitle")}
							<span className="mandatoryField">*</span>
						</span>
					}
					value={chart.display.title || ""}
					onChange={e => handleChange("display.title", e.target.value)}
				/>
			</MDBox>
		</>
	);
};

export default TitleField;
