import { t } from "i18next";
import FieldInput from "./FieldInput";

const ColField = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};
	return (
		<FieldInput
			required
			chart={chart}
			handleChange={handleChange}
			info={t("SETTINGS.CHARTS.NEW.abcisseSelectionInfo")}
			disabled={!request.computeMethod}
			title={t("SETTINGS.CHARTS.NEW.abcisseSelection")}
			field="col"
			dictionary={dictionary}
		/>
	);
};

export default ColField;
