import { Drawer, Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import FiltersTable from "../../../3. DataChoiceStep/FiltersTable";
import {
	mapFiltersDictionary,
	handleChangeFilterRow,
	handleRemoveFilterRow,
	handleAddFilterRow
} from "helpers/chartEditor";

/**
 * Ratio subset component
 * @param {object} param0.chart - Chart object
 * @param {object} param0.dictionary - Dictionary object
 * @param {function} param0.handleChange - Handle change function
 * @returns {JSX.Element} - JSX.Element
 */
const RatioSubsetComponent = ({ chart, dictionary, handleChange }) => {
	const [openDrawer, setOpenDrawer] = useState(false);

	const [filtersArray, setFiltersArray] = useState([]);
	const [subsetFiltersArray, setSubsetFiltersArray] = useState([]);

	const request = chart?.request ?? {};

	const closeDrawer = () => {
		setOpenDrawer(false);
	};

	/**
	 * Init filters arrays
	 */
	async function init() {
		// load filters
		if (!lod_.isNil(chart.filters)) {
			setFiltersArray(prev => {
				return mapFiltersDictionary(chart.filters, dictionary, request.collection);
			});
		}

		if (!lod_.isNil(request.ratioSubset)) {
			setSubsetFiltersArray(prev => {
				return mapFiltersDictionary(request.ratioSubset, dictionary, request.collection);
			});
		}
	}

	/**
	 * Handle change filters in request object
	 * @param {string} path - Path to the filter
	 * @param {any} value - Value of the filter
	 * @param {boolean} deleteMode - Delete mode
	 */
	const handleChangeFilters = (path, value, deleteMode = false) => {
		let ratioSubset = lod_.cloneDeep(request.ratioSubset);

		if (deleteMode) {
			delete ratioSubset[path];
		} else if (lod_.isNil(path)) {
			ratioSubset = value;
		} else {
			ratioSubset[path] = value;
		}

		handleChange("request.ratioSubset", ratioSubset);
	};

	useEffect(() => {
		if (request.computeMethod !== "RATIO_SUBSET") {
			handleChange("request.ratioSubset", null);
			setSubsetFiltersArray([]);
		}

		if (lod_.isNil(request.ratioSubset)) {
			handleChange("request.ratioSubset", {});
			setSubsetFiltersArray([]);
		}
	}, [request.computeMethod]);

	useEffect(() => {
		init();
	}, []);

	// Don't display the component if the computeMethod is not RATIO_SUBSET
	if (request.computeMethod !== "RATIO_SUBSET") {
		return null;
	}

	return (
		<MDBox flex="1" display="flex" flexDirection="column" mt={2}>
			<MDTypography variant="h6">{t("CHARTS.STEPS.DATAS.filterDatas")}</MDTypography>
			<MDBox
				variant="gradient"
				bgColor="info"
				p={1.5}
				borderRadius="md"
				style={{
					cursor: "pointer"
				}}
				onClick={() => setOpenDrawer(true)}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<MDBox display="flex" flexDirection="column">
					<MDBox>
						<MDTypography variant="h6" color="white">
							{t("CHARTS.STEPS.DATAS.showConditions")}
						</MDTypography>
					</MDBox>
					<MDBox>
						<MDTypography variant="body2" color="white">
							{t("CHARTS.STEPS.DATAS.addCondition")}
						</MDTypography>
					</MDBox>
				</MDBox>
				<MDBox mr={3}>
					<Icon fontSize="medium" color="white">
						open_in_new
					</Icon>
				</MDBox>
			</MDBox>
			{/*  */}
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={() => closeDrawer()}
				style={{
					zIndex: 1300
				}}
				PaperProps={{
					sx: { width: "50%" }
				}}
			>
				<MDBox p={2} style={{ height: "100%" }}>
					<FiltersTable
						mode="subset"
						filtersArray={filtersArray}
						subset={subsetFiltersArray}
						handleRemoveFilter={(name, path) => {
							handleRemoveFilterRow(name, path, setSubsetFiltersArray, handleChangeFilters);
						}}
						onClose={closeDrawer}
						onChangeValue={filter => {
							handleChangeFilterRow(filter, setSubsetFiltersArray, handleChangeFilters);
						}}
						handleAddFilter={filter => {
							handleAddFilterRow(filter, dictionary, setSubsetFiltersArray, name => {
								return (
									subsetFiltersArray.find(f => f.name === name) ||
									filtersArray.find(f => f.name === name)
								);
							});
						}}
						dictionary={dictionary}
					/>
				</MDBox>
			</Drawer>
		</MDBox>
	);
};

export default RatioSubsetComponent;
