import { t } from "i18next";
import FieldInput from "./FieldInput";
import { parseTypeDictionary } from "helpers/dictionary";

const FilterDateField = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};
	return (
		<FieldInput
			info={t("SETTINGS.CHARTS.NEW.filterDateAttributeInfo")}
			chart={chart}
			handleChange={handleChange}
			disabled={!request.computeMethod}
			title={t("SETTINGS.CHARTS.NEW.filterDateAttribute")}
			field="filterDateAttribute"
			dictionary={parseTypeDictionary(["date", "datetime"], dictionary)}
		/>
	);
};

export default FilterDateField;
