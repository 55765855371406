/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-nested-ternary */
/* eslint-disable radix */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import MDPagination from "components/Basics/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "components/Advanced/Tables/ComparisonDataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Advanced/Tables/ComparisonDataTable/DataTableBodyCell";
import { t } from "i18next";
import { getHexColor } from "helpers/utilities";

/**
 * ComparisonDataTable component
 * @param {*} param0
 * @returns
 */
function ComparisonDataTable({
	fixedList = false,
	entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch = false,
	showTotalEntries = true,
	table = {},
	pagination = { variant: "gradient", color: "info" },
	noEndBorder = false,
	handleChangeEntriesPerPage = null
}) {
	if (!table?.columns || !table.rows) return null;
	const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
	const entries = entriesPerPage.entries
		? entriesPerPage.entries.map(el => el.toString())
		: ["5", "10", "15", "20", "25"];
	const columns = useMemo(() => table.columns, [table]);

	const data = useMemo(() => table.rows, [table]);

	const totalRow = useMemo(() => table.totalRow, [table]);

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter }
	} = tableInstance;

	const count = table.rows.length;

	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = value => setPageSize(value);

	// Render the paginations
	const renderPagination = pageOptions.map(option => (
		<MDPagination
			item
			key={option}
			onClick={() => gotoPage(Number(option))}
			active={pageIndex === option}
		>
			{option + 1}
		</MDPagination>
	));

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// Setting the entries starting point
	const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = pageSize;
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	/**
	 * Display total line at the end of the table
	 */
	function getTotalRow() {
		if (totalRow) {
			return (
				<TableRow>
					{Object.keys(totalRow).map((key, index) => {
						let cell = table.columns.find(el => el.accessor === key);
						if (!cell) return null;
						return (
							<DataTableBodyCell className="totalCell" key={index}>
								{totalRow[key] ?? 0}
							</DataTableBodyCell>
						);
					})}
				</TableRow>
			);
		} else {
			return null;
		}
	}

	function hasComparaison(cell) {
		if (cell.column.hasComparaison) {
			return "right";
		} else if (cell.column.align) {
			return cell.column.align;
		} else {
			return "left";
		}
	}

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{entriesPerPage || canSearch ? (
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					p={3}
					style={{
						position: "sticky"
					}}
				>
					<MDBox display="flex" alignItems="center">
						<Autocomplete
							disableClearable
							value={pageSize.toString()}
							options={entries}
							onChange={(event, newValue) => {
								if (handleChangeEntriesPerPage) handleChangeEntriesPerPage(newValue);
								setEntriesPerPage(parseInt(newValue, 10));
							}}
							size="small"
							sx={{ width: "5rem" }}
							renderInput={params => <MDInput {...params} />}
						/>
						<MDTypography variant="caption" color="secondary">
							&nbsp;&nbsp;{t("DATATABLE.entriesPerPage")}
						</MDTypography>
					</MDBox>
					{canSearch && (
						<MDBox width="12rem" ml="auto">
							<MDInput
								placeholder={t("DATATABLE.search")}
								size="small"
								fullWidth
								onChange={({ currentTarget }) => {
									setSearch(search);
									onSearchChange(currentTarget.value);
								}}
							/>
						</MDBox>
					)}
				</MDBox>
			) : null}
			<Table>
				<MDBox component="thead">
					{headerGroups.map((headerGroup, groupIndex) => (
						<TableRow key={groupIndex}>
							{headerGroup.headers.map((column, headerIndex) => {
								if (column.hidden) {
									return null;
								}
								return (
									<DataTableHeadCell
										width={column.width ? column.width : "auto"}
										align={column.align ? column.align : "left"}
										className={column.id === "row_total" ? "totalCell" : ""}
										colspan={column.colspan ?? "1"}
										key={headerIndex}
									>
										{column.render("Header")}
									</DataTableHeadCell>
								);
							})}
						</TableRow>
					))}
				</MDBox>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, key) => {
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()} key={key}>
								{row.cells.map((cell, cellKey) => {
									let color = cell.row.original.color ?? "";

									let className = "cell-comparison-table";

									if (cell.column.id === "row_total") {
										className += "totalCell";
									}

									// Depend of the color, the text color will be white or black
									let textColor = "black";

									if (color) {
										// if color is not a hex, convert it to hex
										if (!color.startsWith("#")) {
											color = getHexColor(color);
										}

										const hex = color.replace("#", "");
										const r = parseInt(hex.substring(0, 2), 16);
										const g = parseInt(hex.substring(2, 4), 16);
										const b = parseInt(hex.substring(4, 6), 16);
										const yiq = (r * 299 + g * 587 + b * 114) / 1000;
										textColor = yiq >= 128 ? "black" : "white";
									}

									return (
										<DataTableBodyCell
											noBorder={noEndBorder && rows.length - 1 === key}
											align={hasComparaison(cell)}
											className={className}
											{...cell.getCellProps()}
											key={cellKey}
											style={{
												backgroundColor: color,
												color: textColor
											}}
										>
											{cell.render("Cell")}
										</DataTableBodyCell>
									);
								})}
							</TableRow>
						);
					})}
					{getTotalRow()}
				</TableBody>
			</Table>

			<MDBox
				display="flex"
				flexDirection={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				alignItems={{ xs: "flex-start", sm: "center" }}
				p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
			>
				{!fixedList && showTotalEntries && (
					<MDBox mb={{ xs: 3, sm: 0 }}>
						<MDTypography variant="button" color="secondary" fontWeight="regular">
							{`${t("DATATABLE.showing")} ${entriesStart} ${t("DATATABLE.to")} ${
								count < entriesEnd ? count : entriesEnd
							} ${t("DATATABLE.entries")} (${count} ${t("DATATABLE.entries")})`}
						</MDTypography>
					</MDBox>
				)}
				{!fixedList && pageOptions.length > 1 && (
					<MDPagination
						variant={pagination.variant ? pagination.variant : "gradient"}
						color={pagination.color ? pagination.color : "info"}
					>
						{canPreviousPage && (
							<MDPagination item onClick={() => previousPage()}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
							</MDPagination>
						)}
						{renderPagination.length > 6 ? (
							<MDBox width="5rem" mx={1}>
								<MDInput
									inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
									value={customizedPageOptions[pageIndex]}
									onChange={(handleInputPagination, handleInputPaginationValue)}
								/>
							</MDBox>
						) : (
							renderPagination
						)}
						{canNextPage && (
							<MDPagination item onClick={() => nextPage()}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
							</MDPagination>
						)}
					</MDPagination>
				)}
			</MDBox>
		</TableContainer>
	);
}

export default ComparisonDataTable;
