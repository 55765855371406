import lod_ from "lodash";
import { Icon, Tab, Tabs } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";
import { useEffect, useState } from "react";
import TechnicalDatasDisplay from "./TechnicalDatasDisplay";
import C from "../../constants";
import ChartDisplay from "./ChartDisplay";

const ConfigurationPannel = ({
	chart,
	handleChange,
	dictionary,
	disableChart,
	activeSubStep,
	setActiveSubStep
}) => {
	const [tab, setTab] = useState(activeSubStep);

	const changeTab = val => {
		setActiveSubStep(val);
		setTab(val);
	};

	const type = chart?.type;
	const subType = chart?.display?.subType;

	const TYPE_SUBTYPE = lod_.get(C.CHARTS_SUBTYPE, `${type}`, []);

	const CHART_SUBTYPE = TYPE_SUBTYPE.find(s => s.code === subType) ?? {};
	const MODULE = lod_.get(CHART_SUBTYPE, "modules", {
		data: [],
		display: []
	});

	useEffect(() => {
		setTab(activeSubStep);
	}, [activeSubStep]);

	return (
		<MDBox
			flex="2"
			ml={1}
			style={{
				overflow: "auto"
			}}
		>
			<MDBox mr={1}>
				<Tabs
					orientation="horizontal"
					value={tab}
					onChange={(e, v) => {
						changeTab(v);
					}}
				>
					<Tab
						sx={{
							fontSize: "0.75rem"
						}}
						label={t("CHARTS.STEPS.DISPLAY.configDisplay")}
						icon={
							<Icon fontSize="small" sx={{ mt: -0.25 }}>
								storage
							</Icon>
						}
					/>
					<Tab
						sx={{
							fontSize: "0.75rem"
						}}
						label={t("CHARTS.STEPS.DISPLAY.datasDisplay")}
						icon={
							<Icon fontSize="small" sx={{ mt: -0.25 }}>
								visibility
							</Icon>
						}
					/>
				</Tabs>
			</MDBox>
			{/* Technical datas like compute method, compute attribute, binning, ... */}
			{tab === 0 && (
				<TechnicalDatasDisplay
					chart={chart}
					handleChange={handleChange}
					dictionary={dictionary}
					modules={MODULE}
				/>
			)}
			{/* Displayed datas like title, pages, label, ... */}
			{tab === 1 && (
				<ChartDisplay
					chart={chart}
					handleChange={handleChange}
					dictionary={dictionary}
					disableChart={disableChart}
					modules={MODULE}
				/>
			)}
		</MDBox>
	);
};

export default ConfigurationPannel;
