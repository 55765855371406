import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectFilteredSortedMenu } from "redux-react/reducers/profileReducer";

const PageComponent = ({ chart, handleChange }) => {
	const pages = useSelector(selectFilteredSortedMenu);

	const [selectedPages, setSelectedPages] = useState(
		chart.display?.pages?.map(page => "/" + page) ?? []
	);

	// Change preview chart
	const handleChangeSelect = event => {
		let { value } = event.target;
		setSelectedPages(typeof value === "string" ? value.split(",") : value);
	};

	// Updates selected pages
	useEffect(() => {
		handleChange(
			"display.pages",
			selectedPages.map(page => page.replace("/", ""))
		);
	}, [selectedPages]);

	return (
		<>
			<MDBox mt={2}>
				<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.display")}</MDTypography>
			</MDBox>
			<MDBox mt={1}>
				<FormControl fullWidth>
					<InputLabel id="select-pages-label">
						<span>
							{t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
							<span className="mandatoryField">*</span>
						</span>
					</InputLabel>
					<Select
						labelId="select-pages-label"
						id="select-pages"
						multiple
						value={selectedPages}
						onChange={handleChangeSelect}
						input={
							<OutlinedInput
								id="select-pages-chip"
								label={
									<span>
										{t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
										<span className="mandatoryField">*</span>
									</span>
								}
							/>
						}
						renderValue={selected => (
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
								{selected.map((page, index) => {
									let goodPage = pages.find(p => p.route === page);
									if (goodPage) {
										return <Chip key={index} label={goodPage.name} />;
									} else {
										return null;
									}
								})}
							</Box>
						)}
					>
						{pages.map((page, index) => (
							<MenuItem key={index} value={page.route}>
								{page.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</MDBox>
		</>
	);
};

export default PageComponent;
