import { Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import C from "../../../constants";

const FormatComponent = ({ chart, handleChange, disableChart }) => {
	const POSSIBLE_SUB_TYPES = C.CHARTS_SUBTYPE[chart.type];

	return (
		<>
			<MDTypography variant="h6">{t("SETTINGS.CHARTS.display")}</MDTypography>
			<MDBox mt={1} display="flex">
				{POSSIBLE_SUB_TYPES.map((subType, index) => {
					return (
						<MDBox
							key={index}
							mr={1}
							bgColor={subType.code === chart.display.subType ? "info" : "light"}
							variant="gradient"
							coloredShadow={subType.code === chart.display.subType ? "info" : "light"}
							borderRadius="xl"
							className="colorPickerContainer"
							onClick={() => {
								// Need to disabled chart, then update it
								// to dont have any conflict with data
								disableChart();
								handleChange("display.subType", subType.code);
							}}
						>
							<Icon fontSize="small">{subType.icon}</Icon>
						</MDBox>
					);
				})}
			</MDBox>
		</>
	);
};

export default FormatComponent;
