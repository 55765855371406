import lod_ from "lodash";
import { Icon, Menu, MenuItem } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useState } from "react";

/**
 * Select the method for the filter
 * @param {*} param0 - filter, selectedMethod, methods, setSelectedMethod
 * @returns {JSX.Element} - MethodSelect
 */
const MethodSelect = ({ disabled, filter, selectedMethod, methods, setSelectedMethod }) => {
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);

	if (lod_.isNil(filter)) {
		return null;
	}

	const selectedMethodLabel = methods.find(method => method.code === selectedMethod);

	// No method needed for boolean
	if (filter?.type === "boolean") {
		return (
			<MDBox
				flex="1"
				className="boxInputStyle"
				borderRadius="md"
				style={{
					padding: 0,
					borderRadius: "0",
					borderRight: "none"
				}}
			></MDBox>
		);
	}

	return (
		<>
			<MDBox
				flex="1"
				style={{ border: "none", maring: 0, padding: 0, cursor: disabled ? "" : "pointer" }}
				onClick={e => {
					if (disabled) return;
					setMenuAnchorEl(e.currentTarget);
				}}
			>
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
					style={{ height: "100%" }}
				>
					{/* Displayed text */}
					<MDBox
						flex="1"
						className="boxInputStyle"
						borderRadius="md"
						display="flex"
						alignItems="center"
						style={{
							borderRadius: "0",
							borderRight: "none"
						}}
					>
						<MDTypography variant="body1" fontSize="small">
							{selectedMethod ? t(selectedMethodLabel.label) : t("SETTINGS.select")}
						</MDTypography>
					</MDBox>
					{/* Icon */}
					{!disabled && (
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							style={{
								borderRadius: "0",
								borderLeft: "none",
								borderRight: "none"
							}}
						>
							<Icon fontSize="small">keyboard_arrow_down</Icon>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			{/* Menu */}
			<Menu
				open={menuAnchorEl}
				anchorEl={menuAnchorEl}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{methods.map((method, index) => {
					return (
						<MenuItem
							key={index}
							disabled={method.code === selectedMethod}
							onClick={() => {
								setSelectedMethod(method.code);
								setMenuAnchorEl(null);
							}}
						>
							{t(method.label)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default MethodSelect;
