import { LoadingComplexStatisticsCard } from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import ComparisonTopFlopTableDisplay from "components/Custom/ComparisonTopFlopTable";
import ListOptions from "components/Custom/ListOptions";

const { Card, Icon, Grid, Skeleton, Fade } = require("@mui/material");
const { default: MDBox } = require("components/Basics/MDBox");
const { default: MDTypography } = require("components/Basics/MDTypography");

function ComparisonTopFlopTable({ chart, data, ...rest }) {
	const { editMode = false } = chart;

	if (!chart.loaded) {
		return (
			<Fade in={!chart.loaded}>
				<Grid item {...rest} sx={{ mt: 0, p: 0 }}>
					<MDBox py={2} pr={2} pl={chart.display.icon.component ? 1 : 2}>
						<Skeleton variant="rounded" height={500} />
					</MDBox>
				</Grid>
			</Fade>
		);
	} else if (!data) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data.rows?.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		return (
			<Grid item {...rest}>
				<Card>
					<MDBox py={2} pr={2} pl={chart.display.icon?.component ? 1 : 2}>
						<MDBox
							display="flex"
							justifyContent="space-between"
							px={chart.display.description ? 1 : 0}
							pt={chart.display.description ? 1 : 0}
						>
							<MDBox display="flex">
								{chart.display.icon.component && (
									<MDBox
										width="4rem"
										height="4rem"
										bgColor={chart.display.icon.color || "info"}
										variant="gradient"
										coloredShadow={chart.display.icon.color || "info"}
										borderRadius="xl"
										display="flex"
										justifyContent="center"
										alignItems="center"
										color="white"
										mt={-5}
										mr={2}
									>
										<Icon fontSize="medium">{chart.display.icon.component}</Icon>
									</MDBox>
								)}
								<MDBox mt={chart.display.icon.component ? -2 : 0}>
									{chart.display.title && (
										<MDTypography variant="h6">{chart.display.title}</MDTypography>
									)}
									<MDBox mb={2}>
										<MDTypography component="div" variant="button" color="text">
											{chart.display.description}
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
							{!editMode && (
								<MDBox mt={chart.display.icon.component ? -2 : 0}>
									<ListOptions chart={chart} options={chart.options ?? {}} />
								</MDBox>
							)}
						</MDBox>
						<MDBox>
							<ComparisonTopFlopTableDisplay chart={chart} data={data} />
						</MDBox>
					</MDBox>
				</Card>
			</Grid>
		);
	}
}

export default ComparisonTopFlopTable;
