/* eslint-disable no-restricted-syntax */
import "./style.css";
import SettingsActions from "redux-react/actions/settingsActions";
import { useDispatch } from "react-redux";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import OneValueChart from "pages/charts/oneValueChart";
import ProportionChart from "pages/charts/proportionChart";
import CrossTable from "pages/charts/crossTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import ConfigurationPannel from "./Components/ConfigurationPannel";

/**
 * 4th Step to choose the display of the chart
 * @param {*} param0
 * @returns
 */
const DisplayChoiceStep = ({
	chart,
	setChart,
	validStep,
	activeSubStep,
	setActiveSubStep,
	edit
}) => {
	const [dictionary, setDictionary] = useState({});
	const [error, setError] = useState(false);
	const dispatch = useDispatch();

	const [previewChart, setPreviewChart] = useState(false);

	// get previous request state
	const [previousRequest, setPreviousRequest] = useState(chart.request);

	const handleChange = (path, value, deleteMode = false) => {
		setChart(prev => {
			let copy = lod_.cloneDeep(prev);

			if (deleteMode) {
				lod_.unset(copy, path);
			} else {
				lod_.set(copy, path, value);
			}

			if (copy.display.title && copy.display.pages.length > 0 && !error) {
				validStep();
			} else {
				validStep(false);
			}
			return copy;
		});
	};
	function getCorrectGraph(type) {
		if (!previewChart) return null;
		try {
			switch (type) {
				case "oneValue":
					return (
						<OneValueChart
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
							dictionary={dictionary}
						/>
					);
				case "range":
				case "proportion":
				case "oneDimension":
				case "list":
					return (
						<ProportionChart
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
						/>
					);
				case "crossTable":
				case "timeSeries":
				case "multiCollection":
					return (
						<CrossTable
							chart={{
								...chart,
								loaded: previewChart.loaded
							}}
							data={previewChart.data}
						/>
					);
				case "paginedList":
					return (
						<DefaultDataTable
							list={{
								...chart,
								loaded: previewChart.loaded
							}}
							pagination={previewChart.pagination}
							canSearch
							table={previewChart.data}
							display={chart.request.attributesDisplay}
						/>
					);
				default:
					return null;
			}
		} catch (e) {
			return null;
		}
	}

	// Load chart
	const loadChart = () => {
		const onSuccess = res => {
			if (res.charts.length === 0) {
				setError(true);
			} else {
				setError(false);
				let resChart = res.charts[0];
				resChart.loaded = true;
				setPreviewChart(prev => resChart);
			}
		};

		/**
		 * Before building chart, we need to check if all the required fields are filled
		 * it's depend on the type of the chart
		 */
		let canLoad = true;
		let pathsToCheck = [];

		switch (chart?.type) {
			case "timeSeries": {
				pathsToCheck = [
					"request.binning",
					"request.dateAttribute",
					"request.collection",
					"request.computeMethod"
				];
				break;
			}
			default: {
				pathsToCheck = ["request.collection", "request.computeMethod"];
				break;
			}
		}

		for (let path of pathsToCheck) {
			if (lod_.isNil(lod_.get(chart, path)) || lod_.isEmpty(lod_.get(chart, path))) {
				canLoad = false;
				break;
			}
		}

		if (canLoad) {
			dispatch(ChartsActions.buildRawCharts([chart], [], [], onSuccess));
		}
	};

	/**
	 * Load dictionary
	 * @param {string} codeDictionary - The code of the dictionary
	 * @returns {Promise} - The promise
	 */
	async function loadDictionary(codeDictionary) {
		return new Promise((resolve, reject) => {
			dispatch(
				SettingsActions.getDictionary(codeDictionary, res => {
					setDictionary({
						[codeDictionary]: res.dictionary
					});
					resolve();
				})
			);
		});
	}

	// Updates chart when request change
	useEffect(() => {
		if (!lod_.isEqual(previousRequest, chart.request)) {
			setPreviousRequest(chart.request);
			loadChart();
		}
	}, [chart.request]);

	useEffect(() => {
		if (!lod_.isNil(chart.display.subType)) {
			loadChart();
		}
	}, [chart.display.subType]);

	// Default use effect
	useEffect(() => {
		// Load dictionary
		if (chart.request?.collection) {
			loadDictionary(chart.request?.collection);
		}

		if (edit) {
			// do nothing
		} else {
			let basicDisplay = {
				...chart.display,
				datalabels: false,
				valueAfterPercentage: false,
				label: "",
				title: "",
				description: "",
				icon: {
					component: "equalizer",
					color: "info"
				}
			};

			if (chart.type === "proportion" || chart.type === "range") {
				basicDisplay.label = "%";
				basicDisplay.valueAfterPercentage = true;
				basicDisplay.datalabels = true;
			}

			handleChange("display", basicDisplay);
		}

		loadChart();
	}, []);

	if (lod_.isEmpty(dictionary)) return null;

	return (
		<MDBox
			display="flex"
			style={{
				height: "100%"
			}}
		>
			{/* Left tab */}
			<MDBox flex="3" className="displayLeftTab">
				<MDBox
					className="dialogContentChartEditor fullHeight"
					p={3}
					borderRadius="md"
					style={{
						overflow: "auto"
					}}
				>
					<MDBox flex="1" mt={4}>
						{previewChart && getCorrectGraph(chart.type)}
					</MDBox>
					{error && (
						<MDBox
							display="flex"
							justifyContent="center"
							alignItems="center"
							style={{ width: "100%" }}
						>
							<MDTypography variant="body1" color="textSecondary">
								{i18n.t("SETTINGS.CHARTS.NEW.cannotPreview")}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			{/* Right Tab */}
			<ConfigurationPannel
				activeSubStep={activeSubStep}
				setActiveSubStep={setActiveSubStep}
				chart={chart}
				handleChange={handleChange}
				dictionary={dictionary}
				disableChart={() => setPreviewChart(prev => false)}
			/>
		</MDBox>
	);
};

export default DisplayChoiceStep;
