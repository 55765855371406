import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

const ValueAfterPercentageField = ({ chart, handleChange, dictionary }) => {
	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={() => {
				handleChange("display.valueAfterPercentage", !chart.display.valueAfterPercentage);
			}}
		>
			<span>{t("SETTINGS.CHARTS.NEW.displayValueAfterPercentage")}</span>
			<MDBox>
				<Switch checked={chart.display.valueAfterPercentage ?? false} />
			</MDBox>
		</MDBox>
	);
};

export default ValueAfterPercentageField;
