import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

const SortField = ({ chart, handleChange, dictionary }) => {
	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
		>
			<span>{t("CHARTS.sort")}</span>
			<MDBox>
				<FormControl>
					<RadioGroup
						row
						aria-labelledby="radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={chart.request.sort || "none"}
						onChange={e => handleChange("request.sort", e.target.value)}
					>
						<FormControlLabel value="none" control={<Radio />} label={t("CHARTS.SORT.none")} />
						<FormControlLabel value="asc" control={<Radio />} label={t("CHARTS.SORT.asc")} />
						<FormControlLabel value="desc" control={<Radio />} label={t("CHARTS.SORT.desc")} />
					</RadioGroup>
				</FormControl>
			</MDBox>
		</MDBox>
	);
};

export default SortField;
