/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { t } from "i18next";
import lod_ from "lodash";
import SettingsActions from "redux-react/actions/settingsActions";
/**
 * Query a dictionary with promise
 * @param {string} code - Code of the dictionary
 * @returns {Promise} - Promise with the dictionary
 */
export async function queryDictionary(dispatch, code) {
	return new Promise((resolve, reject) => {
		dispatch(
			SettingsActions.getDictionary(code, res => {
				resolve(res.dictionary);
			})
		);
	});
}

/**
 * Query dictionaries from the API
 * @param {<string>} value
 * @returns {Promise} - Promise with the dictionaries
 */
export async function queryDictionaries(dispatch, codes) {
	const dictionaries = {};

	for (let codeDictionary of codes) {
		let dictionary = await queryDictionary(dispatch, codeDictionary);
		dictionaries[codeDictionary] = dictionary;
	}

	return dictionaries;
}

/**
 * Parse dictionary to get available items by type
 * @param {[String]} value like : ["number"]
 */
export function parseTypeDictionary(arrayValues, dictionary) {
	let availableItems = {};
	// Map key of object
	for (let key in dictionary) {
		let actualLevel = dictionary[key];
		if (actualLevel.type === "level") {
			// Branch
			let result = parseTypeDictionary(arrayValues, actualLevel.items);
			// If we have some results we add the branch to the return
			if (!lod_.isEmpty(result)) {
				let copy = lod_.cloneDeep(actualLevel);
				copy.items = result;
				availableItems[key] = copy;
			}
		} else {
			// When item is sheet (end of the branch)
			if (arrayValues.includes(actualLevel.type)) {
				availableItems[key] = actualLevel;
			}
		}
	}
	return availableItems;
}

/**
 * Get label from dictionary
 * @param {string} attribute - attribute to get label from
 * @param {string} language - language to get label in
 * @returns {string} - label
 */
export const getLabelFromDictionary = (dictionary, attribute, language) => {
	if (dictionary) {
		let attributePath = attribute?.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary.items, attributePath);
		if (dicObject) {
			let label = dicObject.label[language];
			if (!label) {
				label = dicObject.label[Object.keys(dicObject.label)[0]];
			}
			return label;
		}
	}
	return t("SETTINGS.CHARTS.NEW.noSelection");
};
