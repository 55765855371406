/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import lod_ from "lodash";

/**
 * Update request's field
 * @param {string} path - path to the field
 * @param {any} value - value to set
 * @returns {void}
 */
export const updateRequest = (chart, handleChange, dictionary, path, value) => {
	const request = chart.request;
	// Case when user delete a field
	if (lod_.isNil(value)) {
		handleChange(path, null, true);
		return;
	}

	let fieldInDic = value;

	if (path !== "request.reference.value") {
		fieldInDic = value.split(".");

		if (fieldInDic.length > 1) {
			fieldInDic = value.split(".").slice(1).join(".");
		} else {
			fieldInDic = fieldInDic[0];
		}
	}
	// Special case for attribute field
	if (path === "request.attribute") {
		let realPath = value.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);

		if (dicObject && dicObject.type === "timestamp") {
			handleChange("options.formatDate", true);
		} else {
			handleChange("options.formatDate", false);
		}
	}
	// Special case for row
	if (path === "request.row") {
		if (chart.type === "timeSeries") {
			handleChange("options.legend", request.row);
		}
	}

	handleChange(path, fieldInDic);
};

/**
 * Map filters to array
 * @param {*} filters
 * @returns
 */
export const mapFiltersDictionary = (filters, tempDictionary, collection) => {
	let array = [];
	let keys = Object.keys(filters);
	for (let key of keys) {
		let method = Object.keys(filters[key])[0];
		let value = filters[key][method];

		let dic = tempDictionary[collection] ?? {};

		let dicObject = lod_.get(dic.items, key.replaceAll(".", ".items."));
		if (dicObject) {
			let filter = {
				filter: dicObject,
				method,
				value,
				path: `${collection}.${key}`,
				name: key
			};
			array.push(filter);
		}
	}
	return array;
};

/**
 * Filters
 */

/**
 * Handle change on filter's row value / method
 * @param {object} param0 - Filter object
 * @param {Array} setFiltersArray - Set filters array
 * @param {function} handleChangeFilters - Handle change filters
 */
export const handleChangeFilterRow = (
	{ name, path, filter, method, value },
	setFiltersArray,
	handleChangeFilters
) => {
	let valid = !lod_.isNil(method) && !lod_.isNil(value) && !lod_.isEmpty(value?.toString());
	// Need to convert value to string in the check because lodash isEmpty return true for all numbers
	let absolutePath = name;
	if (path) {
		absolutePath = path.split(".").slice(1).join(".");
	}

	let updatedFilter = {
		valid,
		filter,
		path,
		name,
		method,
		value
	};

	setFiltersArray(prev => {
		let copy = lod_.cloneDeep(prev);
		copy = copy.map(f => {
			if (f.name === name) {
				return updatedFilter;
			} else {
				return f;
			}
		});
		return copy;
	});

	if (valid) {
		handleChangeFilters(absolutePath, {
			[method]: value
		});
	}
};

/**
 * Handle remove filter
 * @param {string} name - Filter name
 * @param {string} path - Filter path
 */
export const handleRemoveFilterRow = (name, path, setFiltersArray, handleChangeFilters) => {
	setFiltersArray(prev => {
		return prev.filter(f => f.name !== name);
	});

	handleChangeFilters(path, null, true);
};

/**
 * Handle add filter row
 * @param {object} filter - Filter object
 * @param {object} dictionary - Dictionary
 * @param {Array} setFiltersArray - Set filters array
 * @param {function} exists - Check if filter already exists
 */
export const handleAddFilterRow = (
	filter,
	dictionary,
	setFiltersArray,
	exists = () => {
		return false;
	}
) => {
	let realPath = filter.replaceAll(".", ".items.");
	let dicObject = lod_.get(dictionary, realPath);
	let name = filter.split(".")[filter.split(".").length - 1];
	// user can add only one filter by attribute
	let existInArray = exists(name);

	if (dicObject && !existInArray) {
		setFiltersArray(prev => {
			let copy = lod_.cloneDeep(prev ?? []);
			copy.push({
				filter: dicObject,
				path: filter,
				name
			});
			return copy;
		});
	}
};
