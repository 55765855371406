/* eslint-disable prefer-destructuring */
/* eslint-disable no-fallthrough */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-expressions */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import lod_ from "lodash";
import i18n from "i18next";
// Material Dashboard 2 PRO React components
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import MDPagination from "components/Basics/MDPagination";
import ListOptions from "components/Custom/ListOptions";

import { Card, Checkbox, Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { handleSpecialCell } from "./functions";

const DefaultDataTable = ({
	// Selection
	checkbox = false, // Display checkbox or not ()
	onRowClick = () => {}, // Triggered When no checkbox to handle click on row
	isSelectedRow = () => {
		// Check if the row is selected, override this in component
		return false;
	},
	selectedRows = [], // Selected rows, state must be managed by component
	handleSelection = () => {}, // Triggered when checkbox and a row is selected => return selected rows
	//
	route = {},
	//
	reloadTable,
	//
	noMargin = false,
	//
	headerAction = null,
	//
	displayDictionaryName = "",
	form = {},
	list = {},
	actions = null,
	canSearch = true,
	showTotalEntries = true,
	table = {
		rows: [],
		columns: []
	},
	pagination = {
		defaultEntrie: 10,
		entriesPerPage: [5, 10, 15, 20, 25]
	},
	isSorted = false,
	noHeader = false,
	noEndBorder = false,
	handleChangeEntriesPerPage = null,
	dictionary = {},
	display = [],
	actionDeleteHandle = null,
	actionEditHandle = null,
	filters,
	optionsAction = "left",
	formSelectUpdate = false,
	extra = {}, // Extra props
	//
	unknownColumns = false // Used when we don't know the columns and we need to display all possible columns
}) => {
	const editMode = false;

	const dispatch = useDispatch();
	let defaultEntrie = pagination?.defaultEntrie;
	let entriesPerPage = pagination?.entriesPerPage;
	const [defaultValue, setDefaultValue] = useState(defaultEntrie ?? 10);
	const { profile } = useSelector(state => state);

	const entries = entriesPerPage
		? entriesPerPage.map(el => el.toString())
		: ["5", "10", "15", "20", "25"];

	const [columns, setColumns] = useState(table.columns);
	const [data, setData] = useState(table.rows);
	const [count, setCount] = useState(table.count);

	const sortOrder = [0, 1, -1];
	const [tableSort, setTableSort] = useState({});
	/**
	 * 1 = asc
	 * 0 = default, no sort
	 * -1 = desc
	 */

	const [attributeToDisplay, setAttributeToDisplay] = useState(display);

	const getSort = colID => {
		return tableSort[colID] ?? 0;
	};

	const selectNextSort = colID => {
		const actualSort = tableSort[colID] ?? 0;
		const sort = sortOrder.indexOf(actualSort);
		const nextSort = sortOrder[(sort + 1) % sortOrder.length];
		if (nextSort === 0) {
			setTableSort({});
		} else {
			setTableSort({ [colID]: nextSort });
		}
		// in case we want to sort by firstName, and after lastName, etc ... => setTableSort({ ...tableSort, [colID]: nextSort });
	};

	const { totalRow } = table;

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	let {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter }
	} = tableInstance;

	const [actualPage, setActualPage] = useState(1);

	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = value => setPageSize(value);

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);
	const [customSearch, setCustomSearch] = useState("");

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// Setting the entries starting point
	let entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = pageSize;
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	/**
	 * When pagination is active, the entries start and end are calculated based on the actual page
	 */
	if (pagination) {
		entriesStart = defaultValue * actualPage - defaultValue + 1;
		if (entriesStart < 0) entriesStart = 0;
		entriesEnd = defaultValue * actualPage;

		canPreviousPage = actualPage > 1;
		canNextPage = entriesEnd < count;
	}
	/**
	 * Get the maximum page that can be reached by the input
	 * @returns
	 */
	const getMaxInputValue = cc => {
		if (pagination) {
			return Math.ceil((cc || count) / defaultValue);
		} else {
			return customizedPageOptions.length;
		}
	};
	/**
	 * Go to a specific page, request in DB
	 */
	const goToPagePagination = (
		page = actualPage,
		val = defaultValue,
		searchVal = customSearch,
		sort = tableSort,
		forceRefreshColumns = false
	) => {
		if (list) {
			const onSuccess = res => {
				if (unknownColumns && (lod_.isEmpty(columns) || forceRefreshColumns)) {
					const allPossibleColumns = [];

					for (let i = 0; i < res.data.paginedList.length; i++) {
						const element = res.data.paginedList[i];
						const keys = Object.keys(element);
						for (let j = 0; j < keys.length; j++) {
							const key = keys[j];
							if (!allPossibleColumns.includes(key)) {
								allPossibleColumns.push(key);
								// Break if there are more than 10 columns
								if (allPossibleColumns.length > 10) {
									break;
								}
							}
						}
						// Break if there are more than 10 columns
						if (allPossibleColumns.length > 10) {
							break;
						}
					}

					const newColumns = allPossibleColumns.map((column, index) => {
						return {
							Header: column,
							accessor: column
						};
					});
					setColumns(newColumns);
				}

				setCount(res.data.count);
				setData(res.data.paginedList);

				if (page < 1) {
					page = 1;
				}
				if (page > getMaxInputValue(res.data.count)) {
					page = getMaxInputValue(res.data.count);
				}

				setActualPage(page);

				let maxPage = Math.ceil(res.data.count / val);
				if (maxPage > 0 && page > maxPage) {
					page = maxPage;
					goToPagePagination(page, val, searchVal);
				}
			};

			let profileSystem = false;
			if (profile?.system === true) profileSystem = true;
			dispatch(
				ChartsActions.refreshPaginedList(
					list,
					val,
					page,
					route,
					searchVal,
					sort,
					{ ...filters, ...list.filters },
					onSuccess
				)
			);
		}
	};
	/**
	 * Update the entries per page
	 */
	function handleChangeEntries(newValue) {
		setDefaultValue(newValue);
		goToPagePagination(actualPage, newValue, customSearch);
	}
	/**
	 * Handle the previous page
	 * @param {*} e
	 */
	const handlePreviousPage = e => {
		if (pagination) {
			goToPagePagination(actualPage - 1, defaultValue, customSearch);
		} else {
			previousPage();
		}
	};
	/**
	 * Handle the next page
	 * @param {*} e
	 */
	const handleNextPage = e => {
		if (pagination) {
			goToPagePagination(actualPage + 1, defaultValue, customSearch);
		} else {
			nextPage();
		}
	};
	/**
	 * Handle the change of the page (in input)
	 * @param {*} e
	 */
	const handleChangePage = e => {
		if (pagination) {
			let { value } = e.target;
			if (value < 1) value = 1;
			goToPagePagination(value, defaultValue, customSearch);
		} else {
			handleInputPagination(e);
			handleInputPaginationValue(e);
		}
	};
	/**
	 * Get the actual page
	 * @returns
	 */
	const getActualPage = () => {
		if (pagination) {
			return actualPage;
		} else {
			return customizedPageOptions[pageIndex];
		}
	};
	/**
	 * Search
	 */
	function handleSearch(value) {
		if (pagination) {
			setCustomSearch(value);
			if (value.trim()) {
				goToPagePagination(1, defaultValue, value);
			} else {
				goToPagePagination(1, defaultValue, null);
			}
		} else {
			setSearch(search);
			onSearchChange(value);
		}
	}

	/**
	 * Check if the table has actions
	 */
	const hasActions = actionEditHandle || actionDeleteHandle;
	/**
	 * Display total line at the end of the table
	 */
	function getTotalRow() {
		if (totalRow) {
			return (
				<TableRow>
					{Object.keys(totalRow).map((key, index) => (
						<DataTableBodyCell
							style={{
								fontWeight: "900"
							}}
							key={index}
						>
							{totalRow[key] ?? 0}
						</DataTableBodyCell>
					))}
				</TableRow>
			);
		} else {
			return null;
		}
	}
	/**
	 * ##############################
	 * Handle specials actions in props
	 * -> allow to custom actions with row values
	 * e.g : disabe, click, loadingn style
	 * ##############################
	 */
	function overrideObjectProps(action, row) {
		// custom disabled method
		const checkDisabled = act => {
			if (act.props?.disabled) {
				return act.props.disabled;
			} else if (act.props?.customdisabled) {
				return act.props.customdisabled(row);
			} else {
				return false;
			}
		};
		// custom style
		const customStyle = act => {
			let style = act.props?.style ?? {};
			if (act.props?.customstyle) {
				style = act.props.customstyle(row, style);
			}
			return style;
		};

		const customLoading = act => {
			if (act.props?.loading) {
				let loadingObject = act.props.loading(row);
				if (loadingObject.loading) {
					return loadingObject.component;
				}
			}

			if (act.props?.customicon) {
				return act.props.customicon(row);
			}

			return act.props?.children;
		};

		action = {
			...action,
			props: {
				...action.props,
				onClick(e) {
					action.props?.handleclick ? action.props?.handleclick(row, e) : null;
				},
				disabled: checkDisabled(action),
				style: customStyle(action),
				children: customLoading(action),
				// Pass extra param to props rowvalues
				rowvalues: row.values
			}
		};

		if (
			action.props.children &&
			lod_.isObject(action.props.children) &&
			!Array.isArray(action.props.children)
		) {
			action.props.children = overrideObjectProps(action.props.children, row);
		}

		return action;
	}

	const handleSelectRow = (id, rowValues) => {
		// 1- If in form and no checkbox, we edit the row
		if (formSelectUpdate && !checkbox) {
			actionEditHandle(rowValues);
			return;
		}

		// 2- If no checkbox, onClick event
		if (!checkbox) {
			onRowClick(rowValues);
			return;
		}

		// 3- If checkbox, we select the row
		let newSelectedRows = [];
		if (selectedRows.includes(id) || isSelectedRow(id)) {
			newSelectedRows = selectedRows.filter(item => item.code !== id.code || item.uuid !== id.uuid);
		} else {
			newSelectedRows = [...selectedRows, id];
		}
		handleSelection(newSelectedRows);
	};

	const selectAllRows = () => {
		if (!checkbox) return;
		let newSelectedRows = [];
		if (selectedRows.length === rows.length) {
			handleSelection(newSelectedRows);
		} else {
			rows.map(row => {
				newSelectedRows.push(row.original);
			});
			handleSelection(newSelectedRows);
		}
	};

	// useEffect(() => {
	// console.log(selectedRows);
	// }, [selectedRows]);

	const [prevFilters, setPrevFilters] = useState(null);

	const [anchorMoreVertFormMenu, setAnchorMoreVertFormMenu] = useState(null);
	const handleCloseMoreVertFormMenu = () => {
		setAnchorMoreVertFormMenu(null);
	};
	/**
	 * Menu for pagined list
	 * use dicitonary & form
	 * allow user to display fields of the collection
	 */
	const renderMoreVertFormMenu = () => {
		// Dictionary
		let objectCollection = lod_.get(dictionary, form.routeDictionary);
		if (!objectCollection) {
			return <Menu></Menu>;
		}
		if (objectCollection?.type === "level") objectCollection = objectCollection?.items;
		// Columns
		let columns = list.data.columns;
		let authorizedKey = Object.keys(objectCollection);
		let filteredColumns = [];
		// Display only authorized columns
		Object.keys(columns).filter(item => {
			item = columns[item];
			if (authorizedKey.includes(item.accessor)) {
				filteredColumns.push(item);
			}
		});
		/**
		 * Handle click on item
		 */
		const updateItemFromAttributeToDisplay = attribute => {
			let newAttributeToDisplay = [...attributeToDisplay];
			if (newAttributeToDisplay.includes(attribute)) {
				if (newAttributeToDisplay.length > 1) {
					newAttributeToDisplay = newAttributeToDisplay.filter(item => item !== attribute);
				}
			} else {
				newAttributeToDisplay.push(attribute);
			}
			setAttributeToDisplay(newAttributeToDisplay);
			// Update display in DB
			dispatch(
				ChartsActions.updateAttributeDisplayPaginedList(list.code, newAttributeToDisplay, res => {})
			);
		};
		/**
		 * Menu
		 */
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorMoreVertFormMenu}
				open={Boolean(anchorMoreVertFormMenu)}
				onClose={handleCloseMoreVertFormMenu}
			>
				{Object.keys(filteredColumns).map((item, index) => {
					let itemObject = filteredColumns[item];
					return (
						<MenuItem
							disabled={
								attributeToDisplay.includes(itemObject.accessor) && attributeToDisplay.length <= 1
							}
							key={index}
							onClick={() => {
								updateItemFromAttributeToDisplay(itemObject.accessor);
							}}
						>
							<MDBox>
								<Checkbox checked={attributeToDisplay.includes(itemObject.accessor)} />
							</MDBox>
							{itemObject.Header}
						</MenuItem>
					);
				})}
			</Menu>
		);
	};

	const getActionDelete = (row, collectionDefault) => {
		switch (collectionDefault) {
			default:
				return (
					<IconButton
						onClick={event => {
							actionDeleteHandle(row.values);
							event.stopPropagation();
							event.preventDefault();
						}}
					>
						<Icon fontSize="medium">delete</Icon>
					</IconButton>
				);
		}
	};

	const getRowStyle = row => {
		let className = "";
		if (onRowClick) {
			className += " defaultDataTableClickableRow";
		}
		if (isSelectedRow(row)) {
			className += " defaultDataTableSelectedRow";
		}
		return className;
	};

	useEffect(() => {
		if (lod_.isEqual(prevFilters, filters)) {
			goToPagePagination();
		} else {
			setPrevFilters(filters);
			goToPagePagination(0, defaultValue, customSearch);
		}
	}, [filters, tableSort]);

	useEffect(() => {
		goToPagePagination(0, defaultValue, customSearch, tableSort, true);
	}, [list?.request?.collection]);

	useEffect(() => {
		if (lod_.isNil(reloadTable)) return;
		goToPagePagination();
	}, [reloadTable]);

	useEffect(() => {
		if (table.rows) {
			setData(table.rows);
		}
	}, [table.rows]);

	return (
		<Grid flex="1" display="flex" flexDirection="column" sx={{ height: "100%" }}>
			<MDBox
				mt={noMargin ? 0 : 5}
				flex="1"
				display="flex"
				flexDirection="column"
				sx={{ height: "100%" }}
			>
				<Card
					style={{
						flex: 1,
						height: "100%",
						display: "flex",
						flexDirection: "column",
						boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)"
					}}
				>
					<MDBox
						py={2}
						pr={2}
						pl={list?.display?.icon?.component ? 1 : 2}
						flex="1"
						display="flex"
						flexDirection="column"
						sx={{ height: "100%" }}
					>
						{!noHeader && (
							<MDBox display="flex" justifyContent="space-between" px={1} pt={1}>
								<MDBox display="flex">
									{list?.display?.icon?.component && (
										<MDBox
											width="4rem"
											height="4rem"
											bgColor={list?.display.icon.color || "info"}
											variant="gradient"
											coloredShadow={list?.display.icon.color || "info"}
											borderRadius="xl"
											display="flex"
											justifyContent="center"
											alignItems="center"
											color="white"
											mt={-5}
											mr={2}
										>
											<Icon fontSize="medium">{list?.display.icon.component}</Icon>
										</MDBox>
									)}
									<MDBox mt={list?.display?.icon?.component ? -2 : 0}>
										{list?.display?.title && (
											<MDTypography variant="h6">{list?.display?.title}</MDTypography>
										)}
										<MDBox mb={2}>
											<MDTypography component="div" variant="button" color="text">
												{list?.display?.description}
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
								{!editMode && (
									<MDBox mt={list?.display?.icon?.component ? -2 : 0}>
										<ListOptions chart={list} options={{}} />
									</MDBox>
								)}
							</MDBox>
						)}
						<MDBox
							flex="1"
							display="flex"
							flexDirection="column"
							p={1}
							style={{
								overflowY: "auto"
							}}
						>
							{/* Top */}
							{entriesPerPage || canSearch || headerAction ? (
								<MDBox
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									p={1}
									pb={2}
								>
									<MDBox display="flex" alignItems="center">
										<Autocomplete
											disableClearable
											value={pageSize.toString()}
											options={entries}
											onChange={(event, newValue) => {
												if (pagination) {
													handleChangeEntries(newValue);
												} else {
													if (handleChangeEntriesPerPage) handleChangeEntriesPerPage(newValue);
													setEntriesPerPage(parseInt(newValue, 10));
												}
											}}
											size="small"
											sx={{ width: "5rem" }}
											renderInput={params => <MDInput {...params} />}
										/>
										<MDTypography variant="caption" color="secondary">
											&nbsp;&nbsp;{i18n.t("DATATABLE.entriesPerPage")}
										</MDTypography>
									</MDBox>
									{canSearch && (
										<MDBox /* width="12rem" ml="auto" */ flex="1" sx={{ ml: 5 }}>
											<MDInput
												placeholder={i18n.t("DATATABLE.search")}
												// value={search}
												size="small"
												fullWidth
												onChange={({ currentTarget }) => {
													handleSearch(currentTarget.value);
												}}
											/>
										</MDBox>
									)}
									{headerAction}
									{/* Only for form pages */}
									{dictionary && form?.routeDictionary && (
										<>
											<IconButton
												onClick={e => {
													setAnchorMoreVertFormMenu(e.currentTarget);
													e.preventDefault();
													e.stopPropagation();
												}}
											>
												<Icon>more_vert</Icon>
											</IconButton>
											{renderMoreVertFormMenu()}
										</>
									)}
								</MDBox>
							) : null}
							{/* Table */}

							<TableContainer sx={{ height: "100%", boxShadow: "none" }}>
								<Table {...getTableProps()}>
									<MDBox component="thead">
										{headerGroups.map((headerGroup, groupIndex) => (
											<TableRow {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
												{(hasActions || actions) && optionsAction === "left" && (
													<DataTableHeadCell width={20} align="center">
														Actions
													</DataTableHeadCell>
												)}
												{checkbox && (
													<DataTableHeadCell width={20} align="right">
														<Checkbox
															checked={selectedRows.length === rows.length}
															onClick={() => {
																selectAllRows();
															}}
														/>
													</DataTableHeadCell>
												)}
												{headerGroup.headers.map((column, i) => {
													if (
														lod_.isNil(attributeToDisplay) ||
														lod_.isEmpty(attributeToDisplay) ||
														attributeToDisplay.includes(column.id)
													) {
														return (
															<DataTableHeadCell
																key={i}
																{...column.getHeaderProps(
																	isSorted && column.getSortByToggleProps()
																)}
																width="auto"
																align="left"
																sorted={getSort(column.id)}
																onClick={e => {
																	selectNextSort(column.id);
																}}
															>
																{column.render("Header")}
															</DataTableHeadCell>
														);
													}
													return null;
												})}
												{(hasActions || actions) && optionsAction !== "left" && (
													<DataTableHeadCell width={20} align="center">
														Actions
													</DataTableHeadCell>
												)}
											</TableRow>
										))}
									</MDBox>
									<TableBody {...getTableBodyProps()}>
										{page.map((row, key) => {
											prepareRow(row);
											return (
												<TableRow
													key={key}
													{...row.getRowProps()}
													onClick={() => {
														handleSelectRow(row.original, row.values);
													}}
													className={getRowStyle(row.original)}
												>
													{actions && optionsAction === "left" && (
														<DataTableBodyCell align="center">
															<MDBox display="flex" justifyContent="flex-end">
																{actions.map((action, index) => {
																	action = overrideObjectProps(action, row);
																	return <span key={index}>{action}</span>;
																})}
															</MDBox>
														</DataTableBodyCell>
													)}
													{checkbox && (
														<DataTableBodyCell align="right">
															<Checkbox
																checked={
																	selectedRows.includes(row.original) || isSelectedRow(row.original)
																}
																onClick={() => {
																	handleSelectRow(row.original);
																}}
															/>
														</DataTableBodyCell>
													)}
													{row.cells.map((cell, i) => {
														if (
															lod_.isNil(attributeToDisplay) ||
															lod_.isEmpty(attributeToDisplay) ||
															attributeToDisplay.includes(cell.column.id)
														) {
															return (
																<DataTableBodyCell
																	key={i}
																	noBorder={noEndBorder && rows.length - 1 === key}
																	align={cell.column.align ? cell.column.align : "left"}
																	{...cell.getCellProps()}
																	className={`cell_${cell.column.id.replace(".", "_")}`}
																>
																	{handleSpecialCell(
																		cell,
																		key,
																		dictionary,
																		form,
																		list,
																		displayDictionaryName,
																		extra
																	)}
																</DataTableBodyCell>
															);
														}
														return null;
													})}
													{actions && optionsAction !== "left" && (
														<DataTableBodyCell align="center">
															<MDBox display="flex" justifyContent="flex-end">
																{actions.map((action, index) => {
																	action = overrideObjectProps(action, row);
																	return <span key={index}>{action}</span>;
																})}
															</MDBox>
														</DataTableBodyCell>
													)}
													{hasActions && (
														<DataTableBodyCell>
															<IconButton
																onClick={event => {
																	actionEditHandle(row.values);
																	event.stopPropagation();
																	event.preventDefault();
																}}
															>
																<Icon fontSize="medium">edit</Icon>
															</IconButton>
															{getActionDelete(row, form?.collectionDefault)}
														</DataTableBodyCell>
													)}
												</TableRow>
											);
										})}
										{getTotalRow()}
									</TableBody>
								</Table>
							</TableContainer>
							{/* Bottom */}
							<MDBox
								display="flex"
								flexDirection={{ xs: "column", sm: "row" }}
								justifyContent="space-between"
								alignItems={{ xs: "flex-start", sm: "center" }}
								p={!showTotalEntries && pageOptions.length === 1 ? 0 : 1}
							>
								{showTotalEntries && (
									<MDBox mb={{ xs: 3, sm: 0 }}>
										<MDTypography variant="button" color="secondary" fontWeight="regular">
											{`${i18n.t("DATATABLE.showing")} ${entriesStart} ${i18n.t("DATATABLE.to")} ${
												count < entriesEnd ? count : entriesEnd
											} ${i18n.t("DATATABLE.entries")} (${count} ${i18n.t("DATATABLE.entries")})`}
										</MDTypography>
									</MDBox>
								)}
								{(pageOptions.length > 1 || pagination) && (
									<MDPagination variant="gradient" color="info">
										{canPreviousPage && (
											<MDPagination item onClick={handlePreviousPage}>
												<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
											</MDPagination>
										)}
										<MDBox mr={1}>
											<MDInput
												inputProps={{ type: "number", min: 1, max: getMaxInputValue() }}
												value={getActualPage()}
												onChange={handleChangePage}
											/>
										</MDBox>
										<MDBox mr={1}>
											<MDTypography
												fontSize="small"
												variant="body1"
											>{`/ ${getMaxInputValue()}`}</MDTypography>
										</MDBox>
										{canNextPage && (
											<MDPagination item onClick={handleNextPage}>
												<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
											</MDPagination>
										)}
									</MDPagination>
								)}
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</Grid>
	);
};

export default DefaultDataTable;
