/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { gradients, dark } = colors;

function configs(chartRef, labels, datasets, cutout = 60, cb = () => {}) {
	const backgroundColors = [];

	if (datasets.backgroundColors) {
		datasets.backgroundColors.forEach(color => {
			if (gradients[color]) {
				if (color === "info") {
					backgroundColors.push(gradients.info.main);
				} else {
					backgroundColors.push(gradients[color].state);
				}
			} else {
				backgroundColors.push(color);
			}
		});
	} else {
		backgroundColors.push(dark.main);
	}

	return {
		data: {
			labels,
			datasets: [
				{
					...datasets,
					weight: 9,
					cutout,
					tension: 0.9,
					pointRadius: 2,
					borderWidth: 2,
					backgroundColor: backgroundColors,
					fill: false
				}
			]
		},
		options: {
			onClick: event => {
				const chart = chartRef.current;
				if (!chart) return;

				const elements = chart.getElementsAtEventForMode(
					event,
					"nearest",
					{ intersect: true },
					true
				);

				if (elements.length > 0) {
					const datasetIndex = elements[0].datasetIndex;
					const dataIndex = elements[0].index;

					const defaultData = datasets?.defaultData ?? [];

					const data = defaultData[dataIndex];
					cb(data);
				}
			},
			layout: {
				padding: {
					top: 25,
					bottom: 25
				}
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: datasets?.data?.length > 8 ? "bottom" : "left",

					title: {
						display: true,
						padding: 5
					},
					/* Label style */
					labels: {
						usePointStyle: true, // Use custom point styles
						pointStyle: "rect", // Set the shape to a filled rectangle
						boxWidth: 20, // Ensure the square has the right width
						boxHeight: 20, // Set the height to match the width for a square
						padding: 8, // Add some padding between legend items
						font: {
							size: 13
						},

						generateLabels: chart => {
							const maxLabelLength = 10;

							return chart.data.labels.map((label, i) => {
								const meta = chart.getDatasetMeta(0);
								const style = meta.controller.getStyle(i);

								return {
									usePointStyle: true,
									pointStyle: "rect",

									usePointStyle: true,
									pointStyle: "rect",

									text:
										label.length > maxLabelLength
											? label.substring(0, maxLabelLength) + "..."
											: label,

									index: i,
									fillStyle: style.backgroundColor,
									strokeStyle: style.backgroundColor,
									hidden: meta.data[i].hidden
								};
							});
						}
					},
					/* On hover label */
					onHover: (event, legendItem) => {
						const chart = chartRef.current;
						if (!chart || !legendItem) return;

						// Validate legendItem and dataset
						const datasetIndex = legendItem?.datasetIndex ?? 0;
						const index = legendItem?.index ?? 0;

						const meta = chart.getDatasetMeta(datasetIndex);
						const hidden = meta.data[index].hidden;
						if (hidden) {
							return;
						}

						// Highlight the corresponding dataset or show tooltip
						chart.setActiveElements([{ datasetIndex, index }]);
						chart.tooltip.setActiveElements([{ datasetIndex, index }], {
							x: event.x,
							y: event.y
						});
						chart.update();
					},
					/* On click label */
					onClick: (event, legendItem, legend) => {
						const chart = chartRef.current;
						if (!chart || !legendItem) return;

						const datasetIndex = 0; // Assume only one dataset
						const index = legendItem.index;

						const meta = chart.getDatasetMeta(datasetIndex);
						meta.data[index].active = !meta.data[index].active;
						meta.data[index].hidden = !meta.data[index].hidden;

						chart.update();
					}
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							const value = tooltipItem.formattedValue;
							const label = tooltipItem.dataset.label;

							let tooltipValue = value;

							// 1- Display label
							if (label) {
								if (label.trim() === "%") {
									tooltipValue = `${tooltipValue}${label}`;
								} else {
									tooltipValue = `${tooltipValue} ${label}`;
								}
							}

							// 2- Display raw value
							if (tooltipItem.dataset?.valueAfterPercentage) {
								let defaultData = tooltipItem.dataset.defaultData ?? [];
								let defaultValue = defaultData[tooltipItem.dataIndex];

								if (defaultValue) {
									let total = defaultValue.total;

									if (total !== undefined && total !== null) {
										tooltipValue = `${tooltipValue} (${total})`;
									}
								}
							}

							return tooltipValue;
						}
					}
				}
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				}
			}
		}
	};
}

export default configs;
