/* eslint-disable object-shorthand */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
 * Main application charts
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import { Divider, Grid, Icon, Skeleton, Tooltip } from "@mui/material";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { getInterval } from "components/Custom/DatePresets";
import { setFilter, deleteFilter } from "redux-react/reducers/filtersReducers";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { wsEvent } from "redux-react/actions/ws";
import { socket } from "redux-react/middleware/ws";
import i18n from "i18n";
import lod_ from "lodash";
import { read, utils } from "xlsx";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import CrossTable from "./crossTable";
import RuleComponent from "./ruleChart";
import ImportDialog from "./form/import";
import ComparisonChart from "./comparisonChart";
import FloatingActionsMenu from "./Components/FloatingActionsMenu";
import ChartEditingOrder from "./Components/ChartEditingOrder";
import OneValueDisplay from "./oneValueChart/display";
import ProportionChartDisplay from "./proportionChart/display";
import ReviewChartDisplay from "./reviewChart/display";
import DatasetViewPannel from "./Components/DatasetViewPannel";

/**
 * Default component to display charts, works with dynamic datas
 */
export default function ChartsPage({
	route,
	form,
	insertFormItem: insertFormItemProps,
	updateFormItem: updateFormItemProps,
	deleteFormItem: deleteFormItemProps,
	// Chart
	handleEditChart: handleEditChartProps,
	handleAddChart: handleAddChartProps
}) {
	const UPDATE_PAGE_CHART = `updatePageChart_${route.route}`;

	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);
	const filters = useSelector(state => state.filters);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(true);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Dictionary for dialog
	const [dictionary, setDictionary] = useState({});
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);
	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);
	const [reloadTable, setReloadTable] = useState(false);

	// Datas for dialog
	const [importDialog, setImportDialog] = useState({ open: false });
	// File name when import CSV
	const [fileImportName, setFileImportName] = useState("");

	/**
	 * Page edition
	 */
	const [pageEditMode, setPageEditMode] = useState(false);

	// Dataset pannel controller
	const [datasetViewPannel, setDatasetViewPannel] = useState({
		open: false,
		data: {}
	});

	// Insert item in form
	const insertFormItem = () => {
		insertFormItemProps({
			route: route,
			form: form,
			PI: true,
			empty: true,
			selectedId: null,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Update item in form
	const updateFormItem = (items, target) => {
		updateFormItemProps({
			route: route,
			form: form,
			PI: true,
			empty: false,
			selectedId: items._id,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Delete item in form
	const deleteFormItem = (item, target) => {
		deleteFormItemProps({
			form: form,
			target: target,
			item: item,
			callback: () => {
				setReloadTable(!reloadTable);
			}
		});
	};
	// Handle import CSV
	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();

			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				// Open import modal with data
				setImportDialog({ ...importDialog, open: true, values: data });
			};
			reader.readAsArrayBuffer(file);
			// Set file name
			setFileImportName(file.name);
			// Reset input
			event.target.value = null;
		} catch (error) {
			// Error
		}
	};
	// Import CSV datas in DB
	const handleImportCSV = obj => {
		// Add supplementary datas
		obj.collection = form.collectionDefault;
		obj.assistantID = profile.assistantID;
		obj.fileName = fileImportName;

		const onSuccess = res => {
			dispatch(
				display({
					message: `${res.insertedCount} données importées`,
					type: "success"
				})
			);

			setReloadTable(!reloadTable);
		};

		dispatch(FormActions.importCSV(obj, onSuccess));
	};

	/**
	 * Get charts data to be displayed in front
	 */
	const getChartsData = typeList => {
		const filteredCharts = charts.filter(chart =>
			typeList.includes(chart.customType || chart.type)
		);
		const sortedCharts = filteredCharts.sort((a, b) => a.display.rank - b.display.rank);
		return sortedCharts;
	};
	/* Get filters from back */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/* Build default filters */
	function buildDefaultFilters(defaultFilters) {
		let returnObject = {};

		for (let filter of defaultFilters) {
			// If filter has no default value, continue
			if (!filter.defaultValue) {
				continue;
			}
			// Set default value by type
			switch (filter.type) {
				// Datetime
				case "datetime": {
					// Get value & interval
					let defaultValue = filter.defaultValue;
					// Set datetime filter to store, to display it on the page
					dispatch(
						setFilter({
							assistantID: profile.assistantID,
							page: route.route,
							attribute: filter.attribute,
							type: "datetime",
							mode: "auto",
							code: defaultValue
						})
					);

					let interval = getInterval(defaultValue);
					// Mapped value
					returnObject[filter.attribute] = {
						name: filter.attribute,
						type: filter.type,
						value: interval
					};
					break;
				}
				default:
					break;
			}
		}
		return returnObject;
	}
	/* Load charts from back */
	async function loadCharts(defaultFilters = null, myPageFilters = pageFilters) {
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// If there is default filters, set them (ONLY ON PAGE LOAD)
		if (!lod_.isEmpty(defaultFilters)) {
			Object.keys(actualFilters).map(key => {
				let filter = actualFilters[key];
				let defaultFilter = defaultFilters[key];
				// Replace value if it is empty
				if (lod_.isEmpty(filter?.values)) {
					actualFilters[key] = {
						...defaultFilter
					};
				}
			});
		}

		// Remove unused filters on page, we can have filters in local storage that are not on the page
		Object.keys(actualFilters).map(key => {
			let filter = actualFilters[key];
			let pageFilter = (myPageFilters ?? []).find(
				pageFilter => pageFilter.attribute === filter.name
			);
			if (!pageFilter) {
				delete actualFilters[key];
				// delete filter from local storage
				let filterObject = {
					assistantID: profile.assistantID,
					page: route.route,
					attribute: filter.name
				};
				dispatch(deleteFilter(filterObject));
			}
		});

		// Get charts with filters
		dispatch(
			wsEvent({
				event: "getChartsForPage",
				payload: {
					isSystem: route.system ?? false,
					route: route.route,
					filterByAgent: route?.filterByAgent ?? false,
					wsEventResult: UPDATE_PAGE_CHART,
					filters: actualFilters,
					mandatoryFilter: pageFilters.map(filter => filter.attribute)
				}
			})
		);

		setReloadTable(!reloadTable);
	}

	function setPlaceHolderCharts(charts) {
		setCharts(charts);
		setChartsLoading(false);
	}

	function updatePageChart(chart) {
		setCharts(prevCharts => {
			return prevCharts.map(pChart => {
				if (pChart.code === chart.code) {
					return { ...pChart, data: chart.data, loaded: true, dictionary: chart.dictionary };
				}
				return pChart;
			});
		});
	}
	// Actions button for form pages
	const getFormButtons = () => {
		if (form?.active) {
			return (
				<>
					<MDButton
						style={{ height: "100%", marginRight: "0.75rem" }}
						variant="contained"
						color="info"
						onClick={insertFormItem}
					>
						<Icon>add</Icon>&nbsp;
						{i18n.t("FORMS.LABELS.add") + (form?.pageLabel ?? route.name)}
					</MDButton>
					{form?.importCSV === true && (
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							component="label"
							color="info"
						>
							<input
								type="file"
								name="file"
								accept={[
									".xls",
									".xlsx",
									".xlsm",
									".xlsb",
									".xlt",
									".xltx",
									".xltm",
									".xlam",
									".ods",
									".csv"
								]}
								hidden
								onChange={handleImportFile}
							></input>
							<Icon>publish</Icon>&nbsp;Importer un fichier
						</MDButton>
					)}
				</>
			);
		} else {
			return null;
		}
	};
	// Filters in top bar
	const getFilters = () => {
		if (pageFilters) {
			return (
				<MDBox display="flex" alignItems="center">
					{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
					{route?.filterByAgent ? (
						<Tooltip placement="top" title={i18n.t("SETTINGS.PAGES.filterByAgentPage")}>
							<Icon fontSize="large">person</Icon>
						</Tooltip>
					) : (
						<MDBox></MDBox>
					)}
				</MDBox>
			);
		} else {
			return null;
		}
	};

	const handleDeleteChart = code => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.CHARTS.SUCCESS.delete"),
					type: "success"
				})
			);
			loadCharts();
		};
		dispatch(
			SettingsActions.deleteSetting(profile.assistantID, "chart", { code: code }, onSuccess)
		);
	};

	const handleEditChart = code => {
		handleEditChartProps(code, (editedChart, edit) => {
			if (edit) {
				const onSuccess = res => {
					dispatch(
						display({
							message: i18n.t("SETTINGS.CHARTS.SUCCESS.edit"),
							type: "success"
						})
					);
					loadCharts(null, [], editedChart.code);
				};
				dispatch(SettingsActions.editSetting(profile.assistantID, "chart", editedChart, onSuccess));
			}
		});
	};

	const handleAddChart = () => {
		handleAddChartProps(
			{
				"display.pages": [route.route.replace("/", "")]
			},
			(editedChart, edit) => {
				const onSuccess = res => {
					dispatch(
						display({
							message: i18n.t("SETTINGS.CHARTS.SUCCESS.add"),
							type: "success"
						})
					);
					loadCharts(null, [], editedChart.code);
				};
				dispatch(SettingsActions.newSetting(profile.assistantID, "chart", editedChart, onSuccess));
			}
		);
	};

	/**
	 * Handle click on chart's part
	 * @param {object} chart - Chart object
	 * @param {object} dataset - Dataset object of the clicked part
	 * @param {Array<object>} data - Full chart datas
	 */
	const clickHandler = (chart, dataset, data) => {
		const attributeValue = dataset.name;
		const attributeName = chart.request?.group ?? chart.request?.attribute;

		// Get page filters
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);

		// Create the query filter for the dataset part
		let datasetQuery = {};

		switch (attributeValue) {
			case undefined:
			case null:
				// Do nothing
				break;
			case "other": {
				// Other => everything that is not in the list
				let notInArray = data.map(item => item.name).filter(name => name !== "other");
				datasetQuery = {
					[attributeName]: { $nin: notInArray }
				};
				break;
			}
			default:
				// Check if it's a number
				if (!Number.isNaN(Number(attributeValue))) {
					datasetQuery = {
						[attributeName]: { $in: [attributeValue, Number(attributeValue)] }
					};
				} else {
					datasetQuery = {
						[attributeName]: attributeValue
					};
				}
				break;
		}

		dispatch(
			ChartsActions.getChartByCode(
				profile.assistantID,
				`CHART_DATASET_${chart.request.collection}`,
				({ success, chart: chartList }) => {
					if (!success) {
						return;
					}

					const filters = {
						...chart.filters,
						...datasetQuery,
						active: { $in: [null, true] }
					};

					chartList.filters = lod_.merge(chartList.filters, filters);

					dispatch(
						ChartsActions.buildRawCharts([chartList], [], [], res => {
							if (!res.charts || res.charts.length === 0) {
								return;
							}
							const buildedChart = res.charts[0];
							setDatasetViewPannel({
								open: true,
								data: {
									filters: actualFilters,
									chart: buildedChart,
									dataset
								}
							});
						})
					);
				}
			)
		);
	};

	/**
	 * Reorder the charts
	 * @param {<string>} type - Types of the charts
	 * @param {number} from - From index
	 * @param {number} to - To index
	 */
	function handleReorderChart(type, from, to) {
		// 1- Get the current charts
		const currentCharts = getChartsData(type);

		// 2- Clone the current charts
		const reorderedCharts = lod_.cloneDeep(currentCharts);
		// 3- Swap the charts
		const [removed] = reorderedCharts.splice(from, 1);
		reorderedCharts.splice(to, 0, removed);

		// 4- Get the codes of the reordered charts
		const reorderedChartsCodes = reorderedCharts.map(chart => chart.code);

		// 5- Set the rank of the reordered charts
		const reorderedChartsWithRank = reorderedCharts.map((chart, index) => {
			// Set the rank
			chart.display.rank = index;
			// Update the chart in the backend
			dispatch(
				SettingsActions.editSetting(
					profile.assistantID,
					"chart",
					{
						code: chart.code,
						"display.rank": index
					},
					() => {}
				)
			);

			return chart;
		});

		// 6- Update the charts
		setCharts(prevCharts => {
			return prevCharts.map(pChart => {
				if (reorderedChartsCodes.includes(pChart.code)) {
					return reorderedChartsWithRank.find(chart => chart.code === pChart.code);
				}
				return pChart;
			});
		});

		// reorder in back
	}

	/**
	 * Drag handler
	 * @param {*} param0
	 * @returns
	 */
	const dragHandler = ({ type, from, to }) => {
		if (from === to) return;
		handleReorderChart(type, from, to);
	};

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setFirstLoad(true);
		setChartsLoading(true);

		async function load() {
			// Set page filters
			let myPageFilters = await getPageFilters();
			setPageFilters(myPageFilters);
			// Load the charts with default filters
			let defaultFilters = buildDefaultFilters(myPageFilters);
			loadCharts(defaultFilters, myPageFilters);
			// Avoid multiples requests
			setFirstLoad(false);
		}

		load();
		/**
		 * WS Event
		 */
		socket.on(UPDATE_PAGE_CHART, updatePageChart);
		socket.on(`${UPDATE_PAGE_CHART}Placeholder`, setPlaceHolderCharts);

		return () => {
			socket.off(UPDATE_PAGE_CHART, updatePageChart);
			socket.off(`${UPDATE_PAGE_CHART}Placeholder`, setPlaceHolderCharts);
		};
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * When filters change, reload charts
	 */
	useEffect(() => {
		if (!firstLoad) {
			loadCharts();
		}
	}, [filters]);
	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else {
		/* Main component */
		return (
			<DashboardLayout>
				{!lod_.isEmpty(dictionary) && (
					<ImportDialog
						{...importDialog}
						dictionary={lod_.get(dictionary, route.form.routeDictionary)}
						handleClose={() => setImportDialog({ ...importDialog, open: false })}
						handleSave={handleImportCSV}
					/>
				)}

				{/* Drawer for dataset view */}
				<DatasetViewPannel
					{...datasetViewPannel}
					onClose={() =>
						setDatasetViewPannel({
							open: false,
							data: {}
						})
					}
				/>

				{/* Page edition */}
				<FloatingActionsMenu
					edit={pageEditMode}
					handleAddChart={handleAddChart}
					onClick={() => {
						setPageEditMode(!pageEditMode);
					}}
				/>

				<MDBox>
					<DashboardNavbar
						filters={[
							<MDBox>
								{getFormButtons()}
								{getFilters()}
							</MDBox>
						]}
					/>

					<MDBox mt={4}>
						{/* Review */}
						<ReviewChartDisplay
							pageEditMode={pageEditMode}
							getChartsData={getChartsData}
							dragHandler={dragHandler}
							handleEditChart={handleEditChart}
							handleDeleteChart={handleDeleteChart}
						/>
						{/* One value */}
						<OneValueDisplay
							charts={charts}
							setCharts={setCharts}
							pageEditMode={pageEditMode}
							getChartsData={getChartsData}
							dragHandler={dragHandler}
							handleEditChart={handleEditChart}
							handleDeleteChart={handleDeleteChart}
							clickHandler={clickHandler}
						/>
						{/* Proportion graphs */}
						<ProportionChartDisplay
							pageEditMode={pageEditMode}
							getChartsData={getChartsData}
							dragHandler={dragHandler}
							handleEditChart={handleEditChart}
							handleDeleteChart={handleDeleteChart}
							clickHandler={clickHandler}
						/>
						{/* Comparison table */}
						<ComparisonTableDisplay
							pageEditMode={pageEditMode}
							getChartsData={getChartsData}
							dragHandler={dragHandler}
							handleEditChart={handleEditChart}
							handleDeleteChart={handleDeleteChart}
							clickHandler={clickHandler}
						/>
						{/* Cross Tables */}
						<CrossTableDisplay
							pageEditMode={pageEditMode}
							getChartsData={getChartsData}
							dragHandler={dragHandler}
							handleEditChart={handleEditChart}
							handleDeleteChart={handleDeleteChart}
							clickHandler={clickHandler}
						/>
					</MDBox>
				</MDBox>
				{/* Pagined table */}
				<PaginedListDisplay
					pageEditMode={pageEditMode}
					getChartsData={getChartsData}
					dragHandler={dragHandler}
					handleEditChart={handleEditChart}
					route={route}
					form={form}
					filters={filters}
					reloadTable={reloadTable}
					updateFormItem={updateFormItem}
					deleteFormItem={deleteFormItem}
					handleDeleteChart={handleDeleteChart}
				/>
				{/* Rules */}
				<RuleComponentDisplay
					pageEditMode={pageEditMode}
					getChartsData={getChartsData}
					dragHandler={dragHandler}
					handleEditChart={handleEditChart}
					handleDeleteChart={handleDeleteChart}
				/>
			</DashboardLayout>
		);
	}
}

/**
 * Display comparison charts
 * @param {*} param0
 * @returns
 */
const ComparisonTableDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart
}) => {
	const LIST = ["comparison"];

	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<>
			<MDBox display="flex" flexDirection="row" mt={4}>
				{pageEditMode && (
					<ChartEditingOrder
						getChartsData={getChartsData}
						type={LIST}
						dragHandler={dragHandler}
						handleEditChart={handleEditChart}
						mouseIn={editingMouseInCard}
						mouseOut={editingMouseOutCard}
						handleDeleteChart={handleDeleteChart}
					/>
				)}
				<MDBox
					flex="5"
					style={{
						width: "100%",
						overflowX: "auto"
					}}
				>
					<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
						{getChartsData(LIST).map((chart, index) => {
							return (
								<ComparisonChart
									id={chart.code}
									key={index}
									chart={chart}
									data={chart.data}
									mt={5}
									xs={2}
									md={4}
									lg={2}
									xxl={6}
									style={{
										filter: isHovered(chart.code) ? "brightness(0.8)" : ""
									}}
								/>
							);
						})}
					</Grid>
				</MDBox>
			</MDBox>
			{pageEditMode && getChartsData(LIST).length > 0 && <Divider mt={10} mb={10} />}
		</>
	);
};

/**
 * Display cross tables
 * @param {*} param0
 * @returns
 */
const CrossTableDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart
}) => {
	const LIST = ["crossTable", "timeSeries", "multiCollection"];

	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<>
			<MDBox display="flex" flexDirection="row">
				{pageEditMode && (
					<ChartEditingOrder
						getChartsData={getChartsData}
						type={LIST}
						dragHandler={dragHandler}
						handleEditChart={handleEditChart}
						mouseIn={editingMouseInCard}
						mouseOut={editingMouseOutCard}
						handleDeleteChart={handleDeleteChart}
					/>
				)}
				<MDBox
					flex="5"
					style={{
						width: "100%",
						overflowX: "auto"
					}}
				>
					<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
						{getChartsData(LIST).map((chart, index) => {
							return (
								<CrossTable
									id={chart.code}
									chart={chart}
									data={chart.data}
									key={index}
									mt={5}
									xs={2}
									md={4}
									lg={2}
									xxl={6}
									style={{
										filter: isHovered(chart.code) ? "brightness(0.8)" : ""
									}}
								/>
							);
						})}
					</Grid>
				</MDBox>
			</MDBox>
			{pageEditMode && getChartsData(LIST).length > 0 && <Divider mt={10} mb={10} />}
		</>
	);
};

/**
 * Pagined list display
 * @param {*} param0
 * @returns
 */
const PaginedListDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart,
	form,
	route,
	filters,
	reloadTable,
	updateFormItem,
	deleteFormItem
}) => {
	const profile = useSelector(selectCurrentProfile);
	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<MDBox display="flex" flexDirection="row">
			{pageEditMode && (
				<ChartEditingOrder
					getChartsData={getChartsData}
					type={["paginedList"]}
					dragHandler={dragHandler}
					handleEditChart={handleEditChart}
					mouseIn={editingMouseInCard}
					mouseOut={editingMouseOutCard}
					handleDeleteChart={handleDeleteChart}
				/>
			)}
			<MDBox
				flex="5"
				style={{
					width: "100%",
					overflowX: "auto"
				}}
			>
				{getChartsData(["paginedList"]).map((chart, index) => {
					if (chart.loaded) {
						return (
							<DefaultDataTable
								reloadTable={reloadTable}
								dictionary={chart.dictionary}
								id={chart.code}
								form={form}
								list={chart}
								pagination={chart.pagination}
								canSearch
								key={index}
								table={chart.data}
								display={chart.request.attributesDisplay}
								filters={getLocalStorageBackValues(
									profile.selectedAssistant.assistantID,
									route.route,
									filters
								)}
								actionEditHandle={items => updateFormItem(items, chart.request.collection)}
								actionDeleteHandle={items => deleteFormItem(items, chart.request.collection)}
								style={{
									filter: isHovered(chart.code) ? "brightness(0.8)" : ""
								}}
							/>
						);
					} else {
						return <Skeleton height={500} />;
					}
				})}
			</MDBox>
		</MDBox>
	);
};

/**
 * Rule component display
 * @param {*} param0
 * @returns
 */
const RuleComponentDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart
}) => {
	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<MDBox display="flex" flexDirection="row">
			{pageEditMode && (
				<ChartEditingOrder
					getChartsData={getChartsData}
					type={["rule"]}
					dragHandler={dragHandler}
					handleEditChart={handleEditChart}
					mouseIn={editingMouseInCard}
					mouseOut={editingMouseOutCard}
					handleDeleteChart={handleDeleteChart}
				/>
			)}
			<MDBox
				flex="5"
				style={{
					width: "100%",
					overflowX: "auto"
				}}
			>
				{getChartsData(["rule"]).map((chart, index) => {
					let rule = chart.data;
					return (
						<RuleComponent
							key={index}
							rule={rule}
							options={chart.options}
							dictionary={chart.dictionary}
							outputDictionary={chart.outputDictionary}
							style={{
								filter: isHovered(chart.code) ? "brightness(0.8)" : ""
							}}
						/>
					);
				})}
			</MDBox>
		</MDBox>
	);
};
