import lod_ from "lodash";
import { Chip, Icon, IconButton } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import DictionaryButton from "components/Custom/DictionaryButton";
import { t } from "i18next";

const AttributesSelectorComponent = ({ chart, handleChange, dictionary }) => {
	if (chart.display.subType !== "sortedList" || chart.request.group) return null;

	return (
		<MDBox display="flex" mt={1}>
			<MDBox
				borderRadius="lg"
				display="flex"
				alignItems="center"
				bgColor="light"
				sx={{ width: "100%", mr: 1, p: 0.5 }}
				style={{
					border: "1px solid #d2d6da"
				}}
			>
				{!chart.request.attributesDisplay.length ? (
					<MDBox ml={1}>
						<MDTypography fontSize="small">{t("CHARTS.noFieldsToDisplay")}</MDTypography>
					</MDBox>
				) : (
					chart.request.attributesDisplay.map((attribute, index) => {
						let path = attribute.replaceAll(".", ".items.");
						let dicObject = lod_.get(dictionary[chart.request.collection].items, path);
						return (
							<Chip
								key={index}
								sx={{ mr: 0.5 }}
								label={
									<MDBox display="flex" alignItems="center">
										<MDBox>{dicObject.label.fr}</MDBox>
										<IconButton
											size="small"
											onClick={() => {
												let copyAttributesDisplay = lod_.cloneDeep(chart.request.attributesDisplay);
												copyAttributesDisplay.splice(index, 1);
												handleChange("request.attributesDisplay", copyAttributesDisplay);
											}}
										>
											<Icon>close</Icon>
										</IconButton>
									</MDBox>
								}
							/>
						);
					})
				)}
			</MDBox>
			<DictionaryButton
				sx={{ m: 0 }}
				dictionary={dictionary}
				handleClick={value => {
					value = value.split(".");
					value.shift();
					value = value.join(".");
					handleChange("request.attributesDisplay", [...chart.request.attributesDisplay, value]);
				}}
			/>
		</MDBox>
	);
};

export default AttributesSelectorComponent;
