/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";
import { formatMillis } from "helpers/utilities";

function configs(labels, datasets, options) {
	let legendDisplay = options?.legend !== undefined ? options.legend : true;

	return {
		data: {
			labels,
			datasets: [...datasets]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: legendDisplay,
					position: datasets.length > 6 ? "bottom" : "left",

					labels: {
						usePointStyle: true, // Use custom point styles
						pointStyle: "rect", // Set the shape to a filled rectangle
						boxWidth: 10, // Ensure the square has the right width
						boxHeight: 10, // Set the height to match the width for a square
						padding: 15, // Add some padding between legend items
						font: {
							size: 12
						}
					}
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							if (options.formatDate) {
								let value = parseFloat(tooltipItem.formattedValue.replaceAll(" ", ""));
								tooltipItem.formattedValue = formatMillis(value, "short", options.maxDateUnit);
							}
							if (tooltipItem.formattedValue === 0 || tooltipItem.formattedValue === "0") {
								return null;
							}
							return tooltipItem.dataset.label + " : " + tooltipItem.formattedValue;
						}
					}
				}
			},
			interaction: {
				intersect: false,
				mode: "index"
			},

			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						padding: 10,
						color: "#b2b9bf",
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						},
						callback: value => {
							if (options.formatDate) {
								value = formatMillis(value, "short", options.maxDateUnit);
							}
							return value;
						},
						precision: 0
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						color: "#b2b9bf",
						padding: 20,
						font: {
							size: 11,
							family: typography.fontFamily,
							style: "normal",
							lineHeight: 2
						}
					}
				}
			}
		}
	};
}

export default configs;
