/* eslint-disable import/no-extraneous-dependencies */
import lod_ from "lodash";
import parse from "parse-duration";
import {
	Checkbox,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Paper,
	Popper,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import { formatMillis } from "helpers/utilities";
import { ChromePicker } from "react-color";

/**
 * Dialog to add a new bound, depending of the type of the attribute
 * @param {boolean} param0.open - Dialog open state
 * @param {Function} param0.onClose - Function to close the dialog
 * @param {Function} param0.onSave - Function to save the new bound
 * @param {Object} param0.itemDictionary - Dictionary of the attribute
 * @returns {JSX.Element} - Dialog to add a new bound
 */
const AddBoundDialog = ({ open, onClose, onSave, itemDictionary }) => {
	const [value, setValue] = useState("");

	/**
	 * Handles saving a new bound value
	 * @param {string|number} value - Value to save
	 */
	const handleSave = value => {
		let possibleValue = value;
		if (possibleValue === "") return;

		switch (itemDictionary.type) {
			case "timestamp":
				possibleValue = parse(possibleValue);
				break;
			default:
				break;
		}

		onSave(possibleValue);
		onClose();
		setValue("");
	};

	/**
	 * Gets the appropriate content for the dialog based on item type
	 * @returns {JSX.Element} Dialog content
	 */
	const getContent = () => {
		switch (itemDictionary.type) {
			case "timestamp":
				return (
					<MDBox>
						<MDInput
							autoFocus
							fullWidth
							value={value}
							onChange={e => setValue(e.target.value)}
							placeholder={t("RANGE_COMPONENT.value")}
							onKeyPress={e => {
								if (e.key === "Enter") {
									handleSave(value);
								}
							}}
						/>
						<MDTypography
							variant="subtitle2"
							color="textSecondary"
							sx={{ ml: 3, mt: 2, fontWeight: "bold" }}
						>
							{t("RANGE_COMPONENT.acceptedFormat")}
						</MDTypography>
						<MDTypography variant="subtitle2" color="textSecondary" sx={{ ml: 3, mt: 1 }}>
							<MDTypography variant="subtitle2" component="li">
								{t("RANGE_COMPONENT.weeks")}
							</MDTypography>
							<MDTypography variant="subtitle2" component="li">
								{t("RANGE_COMPONENT.days")}
							</MDTypography>
							<MDTypography variant="subtitle2" component="li">
								{t("RANGE_COMPONENT.hours")}
							</MDTypography>
							<MDTypography variant="subtitle2" component="li">
								{t("RANGE_COMPONENT.minutes")}
							</MDTypography>
							<MDTypography variant="subtitle2" component="li">
								{t("RANGE_COMPONENT.seconds")}
							</MDTypography>
						</MDTypography>

						<MDTypography variant="subtitle2" color="textSecondary" sx={{ ml: 3, mt: 1 }}>
							{t("RANGE_COMPONENT.example")}
						</MDTypography>
					</MDBox>
				);
			case "number":
			default:
				return (
					<MDInput
						type="number"
						autoFocus
						fullWidth
						value={value}
						onChange={e => setValue(e.target.value)}
						placeholder={t("RANGE_COMPONENT.value")}
						onKeyPress={e => {
							if (e.key === "Enter") {
								handleSave(value);
							}
						}}
					/>
				);
		}
	};

	return (
		<Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
			<DialogTitle>
				<MDBox>
					<MDTypography variant="h5">{t("RANGE_COMPONENT.addBound")}</MDTypography>
					<MDTypography variant="subtitle2">{t("RANGE_COMPONENT.minTwoBounds")}</MDTypography>
				</MDBox>
			</DialogTitle>
			<DialogContent>{getContent()}</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton variant="contained" color="info" onClick={() => handleSave(value)}>
					{t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Component that handles value range selection with visual bars and color picking
 * @param {object} props - Component properties
 * @param {object} props.chart - Chart configuration object
 * @param {object} props.dictionary - Dictionary containing attribute definitions
 * @param {Function} props.handleChange - Function to handle changes in chart configuration
 * @returns {JSX.Element} Range selection interface
 */
const RangeComponent = ({ chart, dictionary, handleChange }) => {
	const [open, setOpen] = useState(false);
	const [lastChart, setLastChart] = useState(chart);

	/**
	 * Adds a new bound to the chart configuration
	 * @param {number|string} value - Value to add as bound
	 */
	const addNewBound = value => {
		let currentValues = lod_.cloneDeep(chart.request.valuesRange || []);
		let currentColors = lod_.cloneDeep(chart.request.valuesRangeColors || []);

		value = parseInt(value, 10);
		if (lod_.isNil(value) || lod_.isNaN(value)) return;
		if (currentValues.includes(value)) return;

		const firstValue = currentValues[0];
		const lastValue = currentValues[currentValues.length - 1];

		let hasLessInfinity = false;
		let hasMoreInfinity = false;

		if (firstValue === "-Infinity") {
			hasLessInfinity = true;
			currentValues.shift();
		}

		if (lastValue === "Infinity") {
			hasMoreInfinity = true;
			currentValues.pop();
		}

		let mergedValues = [...currentValues, value];
		mergedValues.sort((a, b) => a - b);

		if (hasLessInfinity) {
			mergedValues.unshift("-Infinity");
		}

		if (hasMoreInfinity) {
			mergedValues.push("Infinity");
		}

		currentColors.push({
			color: "#FF0000",
			value
		});

		handleChange("request.valuesRange", mergedValues);
		handleChange("request.valuesRangeColors", currentColors);
	};

	/**
	 * Resets the values range and colors to empty arrays
	 */
	const resetValuesRange = () => {
		handleChange("request.valuesRange", []);
		handleChange("request.valuesRangeColors", []);
	};

	useEffect(() => {
		if (lod_.isNil(chart.request.valuesRange)) {
			resetValuesRange();
		}

		if (lastChart.type !== chart.type || lastChart.request.attribute !== chart.request.attribute) {
			resetValuesRange();
			setLastChart(chart);
		}
	}, [chart.type, chart.request.attribute]);

	if (lod_.isNil(chart.request.attribute) || lod_.isEmpty(chart.request.attribute)) {
		return (
			<MDBox mt={2} bgColor="light" borderRadius="md" p={2}>
				<MDBox display="flex" alignItems="center">
					<MDTypography variant="h6">
						{t("RANGE_COMPONENT.valueRanges")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={t("RANGE_COMPONENT.groupData")} placement="top">
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				</MDBox>
				<MDTypography variant="body2" fontSize="small">
					{t("RANGE_COMPONENT.selectAttribute")}
				</MDTypography>
			</MDBox>
		);
	}

	const itemDictionary = lod_.get(
		dictionary,
		`${chart.request.collection}.items.${chart.request.attribute.replaceAll(".", ".items.")}`
	);

	return (
		<MDBox mt={2}>
			<AddBoundDialog
				open={open}
				onClose={() => setOpen(false)}
				onSave={addNewBound}
				itemDictionary={itemDictionary}
			/>
			{/* Title */}
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox display="flex" alignItems="center">
					<MDTypography variant="h6">
						{t("RANGE_COMPONENT.valueRanges")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={t("RANGE_COMPONENT.groupData")} placement="top" arrow>
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				</MDBox>

				<MDButton
					color="info"
					className="endButtonboxInputStyle"
					display="flex"
					justifyContent="center"
					alignItems="center"
					onClick={e => {
						setOpen(true);
					}}
					size="small"
				>
					<Icon>add</Icon>&nbsp;{t("RANGE_COMPONENT.addBound")}
				</MDButton>
			</MDBox>
			{/*  */}
			<RangeSelector
				chart={chart}
				handleChange={handleChange}
				dictionary={dictionary}
				itemDictionary={itemDictionary}
			/>
		</MDBox>
	);
};

/**
 * Displays a number/value in the range selector with optional delete functionality
 * @param {object} props - Component properties
 * @param {boolean} [props.canDelete=true] - Whether the value can be deleted
 * @param {object} props.itemDictionary - Dictionary containing item type information
 * @param {number|string} props.value - Value to display
 * @param {Function} props.deleteValue - Function to delete the value
 * @param {string} [props.position=null] - Position of the number ('left', 'right', or null)
 * @returns {JSX.Element} Number display component
 */
const BarNumber = ({ canDelete = true, itemDictionary, value, deleteValue, position = null }) => {
	/**
	 * Formats text based on item type
	 * @param {object} item - Item containing type information
	 * @param {string} text - Text to format
	 * @returns {string} Formatted text
	 */
	const getText = (item, text) => {
		switch (item.type) {
			case "timestamp":
				if (text === "Infinity" || text === "+∞") {
					return "+∞";
				}
				if (text === "-Infinity" || text === "-∞") {
					return "-∞";
				}
				return formatMillis(text, "full");
			case "number":
			default:
				return text;
		}
	};

	/**
	 * Gets the CSS class name based on position
	 * @returns {string} CSS class name
	 */
	const getClassName = () => {
		if (position === "left") {
			return "rangeContainerItemLeft";
		}

		if (position === "right") {
			return "rangeContainerItemRight";
		}

		return "";
	};

	return (
		<Tooltip title={canDelete ? t("SETTINGS.remove") : null} placement="top" arrow>
			<MDBox
				className={getClassName()}
				onClick={() => {
					if (!canDelete) return;
					deleteValue(value);
				}}
			>
				<MDTypography
					className={`rangeContainerItem ${canDelete ? "" : "rangeContainerItemCannotDelete"}`}
					variant="caption"
					fontSize="small"
					style={{
						whiteSpace: "nowrap"
					}}
				>
					{position === "left" && `[${getText(itemDictionary, value)}`}
					{position === "right" && `${getText(itemDictionary, value)}[`}
					{position === null && getText(itemDictionary, value)}
				</MDTypography>
			</MDBox>
		</Tooltip>
	);
};

/**
 * Displays a bar representing a range with optional color picker
 * @param {object} props - Component properties
 * @param {Function} props.handleChange - Function to handle changes
 * @param {object} props.chart - Chart configuration
 * @param {number|string} props.value - Value associated with the bar
 * @param {JSX.Element} [props.component1] - First component to display
 * @param {JSX.Element} [props.component2] - Second component to display
 * @returns {JSX.Element} Bar with color picker
 */
const Bar = ({ handleChange, chart, value, component1 = null, component2 = null }) => {
	const currentColors = lod_.cloneDeep(chart.request.valuesRangeColors || []);
	const color = currentColors.find(c => c.value === value)?.color || "#0000FF";

	const [pickerRef, setPickerRef] = useState(null);
	const [pickerColor, setPickerColor] = useState(color);

	const handleChangeBarColor = color => {
		const newColors = currentColors.map(c => {
			if (c.value === value) {
				c.color = color;
			}
			return c;
		});

		handleChange("request.valuesRangeColors", newColors);
	};

	return (
		<MDBox className={component2 !== null ? "rangeContainer" : "rangeContainerSolo"}>
			<MDBox
				className="rangeContainerBar"
				style={{
					backgroundColor: color
				}}
				onClick={e => {
					if (component2 === null) return;
					setPickerRef(e.target);
				}}
			></MDBox>
			<MDBox className="rangeContainerRow">
				{component1}
				{component2}
			</MDBox>

			<Popper
				id={pickerRef ? "simple-popper" : ""}
				open={Boolean(pickerRef)}
				anchorEl={pickerRef}
				placement="right"
				style={{
					zIndex: 10000
				}}
			>
				{() => (
					<ClickAwayListener
						onClickAway={e => {
							setPickerRef(null);
						}}
					>
						<Paper>
							<ChromePicker
								disableAlpha
								color={pickerColor}
								onChange={e => {
									setPickerColor(e.hex);
								}}
								onChangeComplete={e => handleChangeBarColor(e.hex)}
							/>
						</Paper>
					</ClickAwayListener>
				)}
			</Popper>
		</MDBox>
	);
};

/**
 * Component that handles the visual selection and arrangement of range values
 * @param {object} props - Component properties
 * @param {object} props.chart - Chart configuration
 * @param {Function} props.handleChange - Function to handle changes
 * @param {object} props.dictionary - Dictionary containing attribute definitions
 * @param {object} props.itemDictionary - Dictionary for the specific item type
 * @returns {JSX.Element} Range selector interface
 */
const RangeSelector = ({ chart, handleChange, dictionary, itemDictionary }) => {
	const range = lod_.cloneDeep(chart.request.valuesRange ?? []);
	const currentColors = lod_.cloneDeep(chart.request.valuesRangeColors || []);

	let displayRanges = lod_.cloneDeep(range);

	const firstValue = displayRanges[0];
	const lastValue = displayRanges[displayRanges.length - 1];

	let hasLessInfinity = false;
	let hasMoreInfinity = false;

	if (firstValue === "-Infinity") {
		hasLessInfinity = true;
		displayRanges.shift();
	}

	if (lastValue === "Infinity") {
		hasMoreInfinity = true;
		displayRanges.pop();
	}

	/**
	 * Adds a new bound value to the range
	 * @param {number|string} value - Value to add
	 * @param {string} [position] - Position to add the value ('start', 'end', or null)
	 */
	const addValue = (value, position = null) => {
		switch (position) {
			case "start":
				range.unshift(value);
				break;
			case "end":
				range.push(value);
				break;
			default:
				range.push(value);
				break;
		}

		currentColors.push({
			color: "#FF0000",
			value
		});

		handleChange("request.valuesRange", range);
		handleChange("request.valuesRangeColors", currentColors);
	};

	const deleteValue = value => {
		const newValues = chart.request.valuesRange.filter(v => v !== value);
		const newColors = currentColors.filter(v => v.value !== value);

		handleChange("request.valuesRange", newValues);
		handleChange("request.valuesRangeColors", newColors);
	};

	return (
		<MDBox
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			alignItems="stretch"
			style={{
				overflow: "auto"
			}}
			borderRadius="md"
			shadow="md"
			p={0.5}
			mt={1}
			mb={1}
		>
			{/* Add -Inifnity */}
			<Tooltip title={t("RANGE_COMPONENT.includeLowerValues")} placement="bottom" arrow>
				<MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<MDTypography variant="caption" fontSize="small">
						-∞
					</MDTypography>
					<Checkbox
						checked={hasLessInfinity}
						onChange={e => {
							if (hasLessInfinity) {
								deleteValue("-Infinity");
							} else {
								addValue("-Infinity", "start");
							}
						}}
					/>
				</MDBox>
			</Tooltip>
			{/* Ranges */}
			<MDBox
				flex="1"
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
			>
				{/*
				 * If range has -Infinity at the beggining, display correct segment
				 */}
				{hasLessInfinity && range.length > 1 && (
					<Bar
						handleChange={handleChange}
						chart={chart}
						value={range[0]}
						component1={
							<BarNumber
								canDelete={false}
								itemDictionary={itemDictionary}
								value="-∞"
								deleteValue={() => {}}
								position="left"
							/>
						}
						component2={
							<BarNumber
								canDelete
								itemDictionary={itemDictionary}
								value={range[1]}
								deleteValue={deleteValue}
								position="right"
							/>
						}
					/>
				)}
				{/*
				 * If there is no selections, display message
				 */}
				{(range.length === 0 ||
					(range.length === 1 && (range[0] === "Infinity" || range[0] === "-Infinity"))) && (
					<MDTypography
						fontSize="small"
						color="textSecondary"
						variant="caption"
						style={{
							fontStyle: "italic"
						}}
					>
						{t("RANGE_COMPONENT.addAtLeastTwoValues")}
					</MDTypography>
				)}
				{/*
				 * If there is only one selection, display it
				 */}
				{range.length === 1 && range[0] !== "Infinity" && range[0] !== "-Infinity" && (
					<Bar
						handleChange={handleChange}
						chart={chart}
						value={range[0]}
						component1={
							<BarNumber
								canDelete
								itemDictionary={itemDictionary}
								value={range[0]}
								deleteValue={deleteValue}
							/>
						}
					/>
				)}
				{/*
				 * Display all ranges
				 */}
				{displayRanges.slice(0, -1).map((value, index) => (
					<Bar
						handleChange={handleChange}
						key={index}
						chart={chart}
						value={value}
						component1={
							<BarNumber
								canDelete
								itemDictionary={itemDictionary}
								value={value}
								deleteValue={deleteValue}
								position="left"
							/>
						}
						component2={
							<BarNumber
								canDelete
								itemDictionary={itemDictionary}
								value={displayRanges[index + 1]}
								deleteValue={deleteValue}
								position="right"
							/>
						}
					/>
				))}
				{/*
				 * If range has +Infinity at the end, display correct segment
				 */}
				{hasMoreInfinity && range.length > 1 && (
					<Bar
						handleChange={handleChange}
						chart={chart}
						value={range[range.length - 2]}
						component1={
							<BarNumber
								canDelete
								itemDictionary={itemDictionary}
								value={range[range.length - 2]}
								deleteValue={deleteValue}
								position="left"
							/>
						}
						component2={
							<BarNumber
								canDelete={false}
								itemDictionary={itemDictionary}
								value="+∞"
								deleteValue={() => {}}
								position="right"
							/>
						}
					/>
				)}
			</MDBox>
			{/* Add +Infinity */}
			<Tooltip title={t("RANGE_COMPONENT.includeHigherValues")} placement="bottom" arrow>
				<MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<MDTypography variant="caption" fontSize="small">
						+∞
					</MDTypography>
					<Checkbox
						checked={hasMoreInfinity}
						onChange={e => {
							if (hasMoreInfinity) {
								deleteValue("Infinity");
							} else {
								addValue("Infinity", "end");
							}
						}}
					/>
				</MDBox>
			</Tooltip>
		</MDBox>
	);
};

export default RangeComponent;
