/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
import { Autocomplete, Checkbox, Chip, Icon, Menu, MenuItem } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { ButtonClearFilter } from "components/Custom/Filters/DateFilter";
import MDDatePicker from "components/Basics/MDDatePicker";
import { t } from "i18next";
import { useState } from "react";
import MDTypography from "components/Basics/MDTypography";

/**
 * Select the value for the filter
 * @param {*} param0 - filter, selectedValue, selectedMethod, setSelectedValue
 * @returns {JSX.Element} - ValueSelect
 */
const ValueSelect = ({ disabled, filter, selectedValue, selectedMethod, setSelectedValue }) => {
	if (lod_.isNil(filter)) {
		return null;
	}

	/**
	 * Get the correct input to select value for the filter
	 * @returns
	 */
	const getValues = () => {
		let list = null;
		if (filter.codes) {
			list = filter.codes;
		}

		if (filter.whitelist) {
			let nList = {};
			for (let i = 0; i < filter.whitelist.length; i++) {
				let whiteListValue = filter.whitelist[i];

				let correctType = filter.type;

				switch (correctType) {
					case "number":
						whiteListValue = parseInt(whiteListValue);
						break;
					case "string":
						whiteListValue = whiteListValue.toString();
						break;
					default:
						break;
				}

				nList[filter.whitelist[i]] = whiteListValue;
			}
			list = nList;
		}

		if (["$in", "$nin"].includes(selectedMethod)) {
			return (
				<SelectInAutocomplete
					disabled={disabled}
					filter={filter}
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
					list={list}
				/>
			);
		}

		switch (filter.type) {
			case "boolean":
				return (
					<BooleanInput
						disabled={disabled}
						selectedValue={selectedValue}
						setSelectedValue={setSelectedValue}
					/>
				);
			case "datetime":
				return (
					<DateTimeInput
						disabled={disabled}
						filter={filter}
						selectedValue={selectedValue}
						setSelectedValue={setSelectedValue}
					/>
				);
			case "timestamp":
				return (
					<TimeStampInput
						disabled={disabled}
						filter={filter}
						selectedValue={selectedValue}
						setSelectedValue={setSelectedValue}
					/>
				);
			default:
				if (list) {
					if (!lod_.isArray(list)) {
						let newArray = [];
						let keys = Object.keys(list);
						for (let key of keys) {
							switch (filter.type) {
								case "number":
									key = parseFloat(key);
									break;
								default:
									break;
							}

							newArray.push({
								value: key,
								label: list[key]
							});
						}
						list = newArray;
					}
					return (
						<SelectInMenu
							disabled={disabled}
							value={selectedValue}
							values={list}
							onClick={setSelectedValue}
						/>
					);
				} else {
					return (
						<DefaultInput
							disabled={disabled}
							filter={filter}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
						/>
					);
				}
		}
	};

	return getValues();
};

/**
 * TimeStamp input
 * @param {*} param0 - filter, selectedValue, setSelectedValue
 * @returns {JSX.Element} - DatePicker
 */
const TimeStampInput = ({ disabled, filter, selectedValue, setSelectedValue }) => {
	return (
		<MDBox flex="1">
			<MDDatePicker
				disabled={disabled}
				size="small"
				value={selectedValue}
				options={{
					enableTime: true,
					noCalendar: true,
					dateFormat: "H : i",
					time_24hr: true,
					defaultDate: "01:00"
				}}
				onChange={date => {
					let newDate = new Date(date);
					let hours = newDate.getHours();
					let minutes = newDate.getMinutes();
					let timeInTS = hours * 60 * 60 + minutes * 60;

					setSelectedValue(timeInTS);
				}}
				input={{
					placeholder: `${filter.label.fr} (${t("FILTERS.date.start")})`,

					sx: {
						"& fieldset": {
							border: "1px solid #d2d6da",
							borderRadius: "0 0.375rem 0.375rem 0"
						},
						"& .MuiInputBase-root": {
							flex: 1
						}
					},
					style: {
						height: "100%"
					}
				}}
			/>
		</MDBox>
	);
};

/**
 * Date time input
 * @param {*} param0 - filter, selectedValue, setSelectedValue
 * @returns {JSX.Element} - DatePicker
 */
const DateTimeInput = ({ disabled, filter, selectedValue, setSelectedValue }) => {
	return (
		<MDBox flex="1">
			<MDDatePicker
				disabled={disabled}
				size="small"
				value={selectedValue}
				options={{
					time_24hr: true,
					enableTime: true,
					dateFormat: "d M Y H:i"
				}}
				onChange={date => {
					setSelectedValue(new Date(date));
				}}
				input={{
					placeholder: `${filter.label.fr} (${t("FILTERS.date.start")})`,

					sx: {
						"& fieldset": {
							border: "1px solid #d2d6da",
							borderRadius: "0 0.375rem 0.375rem 0"
						},
						"& .MuiInputBase-root": {
							flex: 1
						}
					},
					style: {
						height: "100%",
						width: "100%"
					}
				}}
			/>
			<ButtonClearFilter updateDate={() => setSelectedValue(null)} />
		</MDBox>
	);
};

/**
 * Boolean input
 * @param {*} param0 - selectedValue, setSelectedValue
 * @returns {JSX.Element} - Checkbox
 */
const BooleanInput = ({ disabled, selectedValue, setSelectedValue }) => {
	return (
		<MDBox
			flex="1"
			className="boxInputStyle"
			borderRadius="md"
			display="flex"
			alignItems="center"
			justifyContent="center"
			style={{
				padding: 0,
				borderRadius: "0 0.375rem 0.375rem 0"
			}}
			onClick={e => !disabled && setSelectedValue(!selectedValue)}
		>
			<Checkbox size="small" checked={Boolean(selectedValue)} />
		</MDBox>
	);
};

/**
 * Select in autocomplete
 * @param {*} param0 - filter, selectedValue, setSelectedValue, list
 * @returns {JSX.Element} - Autocomplete
 */
const SelectInAutocomplete = ({ disabled, filter, selectedValue, setSelectedValue, list }) => {
	if (!list) {
		list = [];
	}

	let options = filter.codes || Object.values(list) || [];

	// Codes can be an object so parse it to an array to be
	// able to use it in the Autocomplete component
	if (lod_.isObject(options) && !lod_.isArray(options)) {
		options = Object.entries(options).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		});
	}

	if (lod_.isNil(selectedValue)) {
		selectedValue = [];
	} else if (!lod_.isArray(selectedValue)) {
		selectedValue = [selectedValue];
	}

	return (
		<Autocomplete
			disabled={disabled}
			style={{
				flex: 1
			}}
			size="small"
			value={selectedValue}
			onChange={(event, newValue) => {
				setSelectedValue(newValue);
				let mappedValues = newValue.map(item => (item.value ? item.value : item));
				setSelectedValue(mappedValues);
			}}
			multiple
			options={options}
			freeSolo
			renderTags={(tags, getTagProps) => {
				if (!lod_.isArray(tags)) {
					return [];
				}
				return tags.map((option, index) => {
					let label = option;

					if (lod_.isObject(option)) {
						label = option.label;
					}
					return <Chip key={index} label={label} {...getTagProps({ index })} />;
				});
			}}
			renderInput={params => (
				<MDBox flex="1" style={{ height: "100%" }}>
					<MDInput
						sx={{
							"& fieldset": {
								border: "1px solid #d2d6da",
								borderRadius: "0 0.375rem 0.375rem 0"
							},
							"& .MuiInputBase-root": {
								flex: 1
							}
						}}
						style={{
							height: "100%"
						}}
						{...params}
						placeholder={`... ${filter.label.fr}`}
					/>
				</MDBox>
			)}
		/>
	);
};

/**
 * Select in menu
 * @param {*} param0 - value, values, onClick
 * @returns {JSX.Element} - Menu
 */
const SelectInMenu = ({ disabled, value, values, onClick }) => {
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);

	const valueLabel = values.find(val => val.value === value);

	return (
		<>
			<MDBox
				flex="1"
				style={{ border: "none", maring: 0, padding: 0, cursor: disabled ? "" : "pointer" }}
				onClick={e => {
					if (disabled) return;
					setMenuAnchorEl(e.currentTarget);
				}}
			>
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="stretch"
					style={{ height: "100%" }}
				>
					{/* Displayed text */}
					<MDBox
						flex="1"
						className="boxInputStyle"
						borderRadius="md"
						display="flex"
						alignItems="center"
						style={{
							borderRadius: "0",
							borderRight: "none"
						}}
					>
						<MDTypography variant="body1" fontSize="small">
							{value && valueLabel?.label ? valueLabel.label : t("SETTINGS.select")}
						</MDTypography>
					</MDBox>
					{/* Icon */}

					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						style={{
							borderLeft: "none"
						}}
					>
						{!disabled && <Icon fontSize="small">keyboard_arrow_down</Icon>}
					</MDBox>
				</MDBox>
			</MDBox>
			{/* Menu */}
			<Menu
				open={menuAnchorEl}
				anchorEl={menuAnchorEl}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{values.map((val, index) => {
					return (
						<MenuItem
							key={index}
							selected={val.value === value}
							onClick={() => {
								onClick(val.value);
								setMenuAnchorEl(null);
							}}
						>
							{val.label}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

/**
 * Default input
 * @param {*} param0 - filter, selectedValue, setSelectedValue
 * @returns {JSX.Element} - Input
 */
const DefaultInput = ({ disabled, filter, selectedValue, setSelectedValue }) => {
	return (
		<MDInput
			disabled={disabled}
			size="small"
			type={filter.type}
			sx={{
				"& fieldset": {
					border: "1px solid #d2d6da",
					borderRadius: "0 0.375rem 0.375rem 0"
				},
				"& .MuiInputBase-root": {
					flex: 1
				}
			}}
			style={{
				flex: 1
			}}
			value={selectedValue}
			onChange={e =>
				setSelectedValue(filter.type === "number" ? Number(e.target.value) : e.target.value)
			}
			placeholder={t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
		/>
	);
};

export default ValueSelect;
