import lod_ from "lodash";
import { t } from "i18next";
import { useState } from "react";
import { Icon, Tooltip } from "@mui/material";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { getLabelFromDictionary } from "helpers/dictionary";
import { updateRequest } from "helpers/chartEditor";

const FieldInput = ({
	info = null,
	chart,
	handleChange,
	disabled = false,
	title,
	field,
	dictionary,
	required = false
}) => {
	const request = chart?.request ?? {};
	const [anchorElOutput, setAnchorElOutput] = useState(null);

	return (
		<MDBox mt={2}>
			<MDBox display="flex" alignItems="center">
				<MDTypography variant="h6">
					{title}
					{required && <span className="mandatoryField">*</span>}
				</MDTypography>
				{info && (
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={info} placement="top" arrow>
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				)}
			</MDBox>

			<MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch">
				<MDBox
					flex="1"
					className="boxInputStyle"
					borderRadius="md"
					style={{
						borderRadius: "0.375rem 0 0 0.375rem"
					}}
				>
					{getLabelFromDictionary(dictionary[request?.collection], lod_.get(request, field))}
				</MDBox>
				{/* Clear value for not required fields */}
				{!required && (
					<Tooltip placement="top" title={t("SETTINGS.remove")}>
						<MDBox
							className="endButtonboxInputStyle"
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="light"
							onClick={e =>
								updateRequest(chart, handleChange, dictionary, `request.${field}`, null)
							}
							style={{
								borderRadius: "0"
							}}
						>
							<Icon fontSize="small">close</Icon>
						</MDBox>
					</Tooltip>
				)}
				{/* Open dictionary menu */}
				<Tooltip placement="top" title={t("SETTINGS.select")}>
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						onClick={e => setAnchorElOutput(e.target)}
					>
						<Icon fontSize="small">menu</Icon>
					</MDBox>
				</Tooltip>
				{/* Dictionary menu */}
				<DictionaryMenu
					placement="right"
					dictionary={dictionary}
					anchorEl={anchorElOutput}
					handleInsertText={e => {
						setAnchorElOutput(null);
						updateRequest(chart, handleChange, dictionary, `request.${field}`, e);
					}}
					handleClose={() => setAnchorElOutput(null)}
				/>
			</MDBox>
		</MDBox>
	);
};

export default FieldInput;
