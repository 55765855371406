import lod_ from "lodash";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";
import C from "../../../constants";
import { useEffect } from "react";

const ComputeMethodField = ({ chart, dictionary, handleChange }) => {
	const isDisabled = () => {
		if (lod_.isNil(chart.request.collection)) return true;
		return false;
	};

	useEffect(() => {
		if (chart.type === "range" && chart.request.computeMethod !== "AVG") {
			handleChange("request.computeMethod", "COUNT");
		}
	}, [chart.type, chart.request.computeMethod]);

	if (chart.type === "range") {
		return null;
	}

	return (
		<MDBox flex="1">
			<MDTypography variant="h6">
				{t("SETTINGS.CHARTS.NEW.computeMethodSelection")}
				<span className="mandatoryField">*</span>
			</MDTypography>
			<MDBox>
				<FormControl fullWidth>
					<InputLabel>{t("SETTINGS.select")}</InputLabel>
					<Select
						label={t("SETTINGS.select")}
						disabled={isDisabled()}
						labelId="select-label"
						id="select"
						value={chart.request.computeMethod}
						onChange={e => {
							let method = C.COMPUTE_METHOD.find(m => m.code === e.target.value);
							if (!lod_.isNil(method)) {
								handleChange("request.computeMethod", method.code);
								handleChange("options.formatDate", false);
							}
						}}
					>
						{C.COMPUTE_METHOD.map((method, index) => (
							<MenuItem key={index} value={method.code}>
								<MDBox>
									<MDBox display="flex" alignItems="center">
										<MDBox display="flex" alignItems="center" mr={1}>
											<Icon>{method.icon}</Icon>
										</MDBox>
										<MDTypography variant="h6">{t(method.label)}</MDTypography>
									</MDBox>
									<MDTypography variant="body2">{t(method.description)}</MDTypography>
								</MDBox>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</MDBox>
		</MDBox>
	);
};

export default ComputeMethodField;
