import { t } from "i18next";
import lod_ from "lodash";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

import FormatComponent from "./display/FormatComponent";
import TitleField from "./display/TitleField";
import DescriptionField from "./display/DescriptionField";
import IconComponent from "./display/IconComponent";
import PageComponent from "./display/PageComponent";
import AttributesSelectorComponent from "./display/AttributesSelectorComponent";
import PreviousPeriodField from "./display/PreviousPeriod";
import LimitField from "./display/LimitField";
import LabelField from "./display/LabelField";
import DatalabelsField from "./display/DatalabelsField";
import TopLimitComponent from "./display/TopLimitComponent";
import SortField from "./display/SortField";
import SortedListSortField from "./display/SortedListSortField";
import DisplayLegendField from "./display/DisplayLegendField";
import ValueAfterPercentageField from "./display/ValueAfterPercentageField";

/**
 * Display tab
 * @param {*} param0
 * @returns {JSX.Element} - Display tab
 */
const ChartDisplay = ({ chart, handleChange, dictionary, disableChart, modules }) => {
	const MODULE_MAPPING = {
		previousPeriodFilter: PreviousPeriodField,
		limitField: LimitField,
		labelField: LabelField,
		datalabelsField: DatalabelsField,
		topLimitComponent: TopLimitComponent,
		sortField: SortField,
		sortedListSortField: SortedListSortField,
		valueAfterPercentageField: ValueAfterPercentageField,
		displayLegendField: DisplayLegendField
	};

	const DEFAULT_MODULES = [];

	const FINALE_MODULES = [...DEFAULT_MODULES, ...modules.display];

	return (
		<MDBox mt={2}>
			{/* Format */}
			<FormatComponent chart={chart} handleChange={handleChange} disableChart={disableChart} />
			{/* Titre */}
			<TitleField chart={chart} handleChange={handleChange} />
			{/* Description */}
			<DescriptionField chart={chart} handleChange={handleChange} />
			{/* Icon */}
			<IconComponent chart={chart} handleChange={handleChange} />
			{/* Pages */}
			<PageComponent chart={chart} handleChange={handleChange} />
			{/* Attributes display
			 * Only for sortedList subType chart and if no group by
			 */}
			<AttributesSelectorComponent
				chart={chart}
				handleChange={handleChange}
				dictionary={dictionary}
			/>
			{/* Options */}
			{!lod_.isEmpty(FINALE_MODULES) && (
				<MDBox mt={2}>
					<MDTypography variant="h6">{t("SETTINGS.CHARTS.NEW.options")}</MDTypography>
				</MDBox>
			)}

			<MDBox mt={0}>
				{FINALE_MODULES.map((module, index) => {
					const ModuleComponent = MODULE_MAPPING[module];
					if (ModuleComponent) {
						return <ModuleComponent key={index} {...{ chart, dictionary, handleChange }} />;
					} else {
						return null;
					}
				})}
			</MDBox>
		</MDBox>
	);
};

/**
 * Display components
 */

export default ChartDisplay;
