/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
import "./style.css";
import { Card, Fade, Grid, Skeleton } from "@mui/material";
import {
	LoadingComplexStatisticsCard,
	ComplexStatisticsCard
} from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import RankingCard from "components/Advanced/Cards/StatisticsCards/RankingCard";
import MDBox from "components/Basics/MDBox";
import TemperatureCard from "components/Custom/TemperatureCard";

const OneValueChart = ({
	chart,
	data,
	dictionary,
	handleEditChart: handleEditChartProps,
	resizeChart: resizeChartProps,
	clickHandler = () => {},
	...rest
}) => {
	const handleEditChart = () => {
		handleEditChartProps(chart.code);
	};

	const resizeChart = size => {
		resizeChartProps(chart.code, size);
	};

	if (!chart.loaded) {
		return (
			<Fade in={!chart.loaded}>
				<Grid item {...rest} sx={{ m: 0, p: 0 }} className="oneValueChartGrid">
					<Skeleton variant="rounded" height={150} />
				</Grid>
			</Fade>
		);
	} else if (!chart) {
		return (
			<Grid item {...rest} className="oneValueChartGrid">
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text="Chargement des données"
					/>
				</MDBox>
			</Grid>
		);
	} /* else if (data.length === 0) {
		return (
			<Grid item {...rest} className="oneValueChartGrid">
				<MDBox mb={1.5}>
					<ComplexStatisticsCard
						count={`0 ${chart.display.label ?? ""}`}
						percentage={{}}
						handleEditChart={handleEditChart}
						resizeChart={resizeChart}
						chart={chart}
					/>
				</MDBox>
			</Grid>
		);
	} */

	/**
	 * Data must be an array with always one only element
	 */
	data = data ?? [];

	if (data.length === 0) {
		data = [
			{
				total: 0,
				total_ratio: 0
			}
		];
	}

	data = data[0];
	let percentage = {};

	if (data.total_ratio) {
		percentage = {
			color: parseFloat(data.total_ratio) > 0 ? "success" : "error",
			amount:
				parseFloat(data.total_ratio) > 0 ? `+ ${data.total_ratio} %` : `${data.total_ratio} %`,
			label: "par rapport à la période précédente"
		};
	}

	switch (chart.display.subType) {
		case "ranking":
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<RankingCard
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
							handleEditChart={handleEditChart}
							dictionary={dictionary}
							resizeChart={resizeChart}
							chart={chart}
							data={data}
						/>
					</MDBox>
				</Grid>
			);
		case "card":
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
							handleEditChart={handleEditChart}
							resizeChart={resizeChart}
							chart={chart}
							data={data}
							dictionary={dictionary}
							clickHandler={dataset => clickHandler(chart, dataset, data)}
						/>
					</MDBox>
				</Grid>
			);
		case "temperature":
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<TemperatureCard
							icon={chart.display.icon}
							title={chart.display.title}
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
							dictionary={dictionary}
						/>
					</MDBox>
				</Grid>
			);
		default:
			return (
				<Grid item {...rest}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							count={`${data.total} ${chart.display.label ?? ""}`}
							percentage={percentage}
							handleEditChart={handleEditChart}
							resizeChart={resizeChart}
							chart={chart}
							data={data}
							dictionary={dictionary}
						/>
					</MDBox>
				</Grid>
			);
	}
};

export default OneValueChart;
