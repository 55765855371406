import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";

const DescriptionField = ({ chart, handleChange }) => {
	if (chart.type === "oneValue") return null;

	return (
		<MDBox mt={1}>
			<MDInput
				className="dialogInput"
				label={t("SETTINGS.CHARTS.NEW.chartDescription")}
				value={chart.display.description || ""}
				onChange={e => handleChange("display.description", e.target.value)}
			/>
		</MDBox>
	);
};

export default DescriptionField;
