/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import lod_ from "lodash";
import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";

/**
 * Get the path's label for a dictionary
 * @param {*} dictionary
 * @param {*} path
 * @returns
 */
const getPath = (dictionary, path) => {
	if (!path) {
		return t("SETTINGS.select");
	}

	const parts = path.split(".");

	const result = [];
	let current = "";

	for (let i = 0; i < parts.length; i++) {
		current += parts[i];
		result.push(current);
		current += ".items.";
	}

	let stringPath = "";

	for (let i = 0; i < result.length; i++) {
		stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
	}

	stringPath = stringPath.slice(0, -1);
	return stringPath;
};

const PathSelect = ({ validFilter, path, dictionary, disabled }) => {
	return (
		<MDBox style={{ border: "none", maring: 0, padding: 0 }} flex="1">
			<MDBox
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="stretch"
				style={{ height: "100%" }}
			>
				<MDBox
					flex="1"
					className="boxInputStyle"
					borderRadius="md"
					display="flex"
					alignItems="center"
					style={{
						borderRadius: "0.375rem 0 0 0.375rem",
						borderRight: "none",
						whiteSpace: "normal"
					}}
				>
					<MDTypography variant="body1" fontSize="small">
						{getPath(dictionary, path)}
					</MDTypography>
				</MDBox>
				<MDBox
					className="endButtonboxInputStyle"
					display="flex"
					justifyContent="center"
					alignItems="center"
					style={{
						borderRadius: "0",
						borderLeft: "none",
						borderRight: "none"
					}}
				>
					<MDBox display="flex" alignItems="center">
						<MDBox flex="1" display="flex" alignItems="center">
							<Tooltip
								title={
									validFilter
										? t("SETTINGS.CHARTS.COMPUTE.validFilter")
										: t("SETTINGS.CHARTS.COMPUTE.invalidFilter")
								}
								arrow
								placement="top"
							>
								{validFilter ? (
									<Icon color="success">check</Icon>
								) : (
									<Icon color="error">close</Icon>
								)}
							</Tooltip>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default PathSelect;
