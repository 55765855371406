import { t } from "i18next";
import FieldInput from "./FieldInput";

const GroupField = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};
	return (
		<FieldInput
			required={chart.display.subType !== "sortedList"}
			info={t("SETTINGS.CHARTS.NEW.groupByInfo")}
			chart={chart}
			handleChange={handleChange}
			disabled={!request.computeMethod}
			title={t("SETTINGS.CHARTS.NEW.groupBy")}
			field="group"
			dictionary={dictionary}
		/>
	);
};

export default GroupField;
