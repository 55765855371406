import { t } from "i18next";
import FieldInput from "./FieldInput";

const RowField = ({ chart, dictionary, handleChange }) => {
	const request = chart?.request ?? {};
	return (
		<FieldInput
			info={t("SETTINGS.CHARTS.NEW.ordinateSelectionInfo")}
			chart={chart}
			handleChange={handleChange}
			disabled={!request.computeMethod}
			title={t("SETTINGS.CHARTS.NEW.ordinateSelection")}
			field="row"
			dictionary={dictionary}
		/>
	);
};

export default RowField;
