/* eslint-disable prefer-destructuring */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from "react";
import lod_ from "lodash";
import { t } from "i18next";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { Drawer, FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import i18n from "i18n";
import SettingsActions from "redux-react/actions/settingsActions";
import ChartsActions from "redux-react/actions/chartsActions";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FiltersTable from "./FiltersTable";
import {
	mapFiltersDictionary,
	handleAddFilterRow,
	handleChangeFilterRow,
	handleRemoveFilterRow
} from "helpers/chartEditor";

/**
 * Get the label of an item in a dictionary with the profile language
 * @param {object} label - The label object from dictionary
 * @param {object} profile - The profile object
 * @returns {string} - The label in the profile language
 */
function getItemLabel(label = {}, profile) {
	let languagesKeys = Object.keys(label);

	try {
		let language = profile.language;
		if (languagesKeys.includes(language)) {
			return label[language];
		} else {
			return label[languagesKeys[0]];
		}
	} catch {
		return label[languagesKeys[0]];
	}
}

/**
 * Main component
 */
const DataChoiceStep = ({
	chart,
	handleChange,
	handleChangeFilters,
	collections,
	isStepValid,
	validStep,
	selectedDataset
}) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const request = chart.request;
	const filters = chart.filters;

	const [dictionary, setDictionary] = useState({});

	const [filtersArray, setFiltersArray] = useState([]);

	const [openDrawer, setOpenDrawer] = useState(false);

	const [datasetChart, setDatasetChart] = useState(null);

	const DELETE_MAP = {
		collection: [
			"computeMethod",
			"attribute",
			"group",
			"binning",
			"row",
			"col",
			"filterDateAttribute",
			"dateAttribute",
			"filters"
		],
		computeMethod: ["attribute"]
	};

	const COLLECTIONS_SOURCE_MAP = {
		clusterClient: i18n.t("SETTINGS.CHARTS.dictionariesDatas"),
		catalog: i18n.t("SETTINGS.CHARTS.faibrikDatas")
	};

	/**
	 * Close the drawer
	 */
	const closeDrawer = () => {
		setOpenDrawer(false);
	};

	/**
	 * Load dictionary
	 * @param {string} codeDictionary - The code of the dictionary
	 * @returns {Promise} - The promise
	 */
	async function loadDictionary(codeDictionary) {
		return new Promise((resolve, reject) => {
			dispatch(
				SettingsActions.getDictionary(codeDictionary, res => {
					setDictionary({
						[codeDictionary]: res.dictionary
					});
					resolve({
						[codeDictionary]: res.dictionary
					});
				})
			);
		});
	}

	// Handle when choosing a data source
	const updateCollection = async collection => {
		let toDelete = DELETE_MAP.collection;

		let clonedCopy = lod_.cloneDeep(request);
		clonedCopy.collection = collection.codeDictionary;

		for (let key of toDelete) {
			delete clonedCopy[key];
		}

		handleChange("request", clonedCopy);
		handleChangeFilters(null, {});
		setFiltersArray([]);

		// Request back to get the dictionary
		await loadDictionary(collection.codeDictionary);
	};

	function checkStepValidity() {
		// Check is all is valid
		let isValid = true;

		if (lod_.isNil(request.collection) || lod_.isEmpty(request.collection)) {
			isValid = false;
		}

		validStep(isValid);
	}

	async function init() {
		let tempDictionary = {};

		// Load dictionary
		if (!lod_.isNil(chart.request?.collection) && !lod_.isEmpty(chart.request?.collection)) {
			tempDictionary = await loadDictionary(chart.request.collection);
		}
		// load filters
		if (!lod_.isNil(chart.filters)) {
			setFiltersArray(prev => {
				return mapFiltersDictionary(chart.filters, tempDictionary, chart.request.collection);
			});
		}
	}

	function mapMenuCollection(collections) {
		let menu = {};

		for (let collection of collections) {
			if (collection.storage) {
				if (!menu[collection.storage]) {
					menu[collection.storage] = [];
				}

				menu[collection.storage].push(collection);
			}
		}

		let reactMenuItems = [];
		for (let key in menu) {
			reactMenuItems.push(
				<MenuItem disabled style={{ opacity: 0.7 }}>
					<MDTypography variant="h6" fontSize="small">
						{COLLECTIONS_SOURCE_MAP[key]}
					</MDTypography>
				</MenuItem>
			);

			for (let collection of menu[key]) {
				reactMenuItems.push(
					<MenuItem value={collection.codeDictionary}>
						{getItemLabel(collection.label, profile)}
					</MenuItem>
				);
			}
		}

		return reactMenuItems;
	}

	async function loadDataset(config) {
		const datasetCollection = config.collection;
		const datasetFilters = config.filters ?? {};

		if (!lod_.isNil(datasetCollection)) {
			let collection = collections.find(c => c.codeDictionary === datasetCollection);
			if (!lod_.isNil(collection)) {
				updateCollection(collection);
			}

			let loadedDictionary = await loadDictionary(datasetCollection);

			const mappedFilters = mapFiltersDictionary(
				datasetFilters,
				loadedDictionary,
				datasetCollection
			);

			setFiltersArray(prev => {
				return mappedFilters;
			});
		}
	}

	/**
	 * Get the chart dataset to display datas
	 * Get the chart by code in database, if not found, return default chart
	 * @returns {Promise} - The promise
	 */
	const getDatasetChart = async () => {
		// Default chart, columns will be automatically fetched
		const defaultChart = {
			unknownColumns: true,
			request: {
				collection: request.collection,
				attributes: [],
				attributesDisplay: []
			},
			filters: {},
			options: {},
			data: {
				columns: [],
				rows: []
			}
		};

		return new Promise(resolve => {
			// 1- Get the chart by code
			dispatch(
				ChartsActions.getChartByCode(
					profile.assistantID,
					`CHART_DATASET_${request.collection}`,
					({ success, chart: chartList }) => {
						if (!success) {
							resolve(defaultChart);
						} else {
							// 2- Apply filters
							chartList.filters = {};

							// 3- Build the chart to get datas
							dispatch(
								ChartsActions.buildRawCharts([chartList], [], [], res => {
									if (!res.charts || res.charts.length === 0) {
										resolve(defaultChart);
									} else {
										const buildedChart = res.charts[0];
										resolve(buildedChart);
									}
								})
							);
						}
					}
				)
			);
		});
	};

	useEffect(() => {
		if (!lod_.isNil(selectedDataset) && !lod_.isNil(selectedDataset.config)) {
			loadDataset(selectedDataset.config);
		}
	}, [selectedDataset]);

	// On collection change
	useEffect(() => {
		setDatasetChart(null);
		const loadDatasetChart = async () => {
			if (request.collection) {
				const result = await getDatasetChart();
				setDatasetChart(result);
			}
		};

		loadDatasetChart();

		checkStepValidity();
	}, [request.collection]);

	// On page load
	useEffect(() => {
		// Init component
		init();
	}, []);

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			style={{
				height: "100%",
				width: "100%"
			}}
		>
			<MDBox display="flex" flexDirection="row">
				{/* Data source */}
				<MDBox flex="1" mr={1}>
					<MDTypography variant="h6">
						{i18n.t("SETTINGS.CHARTS.NEW.dataSourceSelection")}
						<span className="mandatoryField">*</span>
					</MDTypography>
					<MDBox>
						<FormControl fullWidth>
							<InputLabel>{t("SETTINGS.select")}</InputLabel>
							<Select
								label={t("SETTINGS.select")}
								labelId="select-label"
								id="select"
								value={request.collection}
								onChange={e => {
									let collection = collections.find(c => c.codeDictionary === e.target.value);
									updateCollection(collection);
								}}
								renderValue={key => {
									const collection = collections.find(c => c.codeDictionary === key);
									const label = getItemLabel(collection.label, profile);
									const storage = COLLECTIONS_SOURCE_MAP[collection.storage];
									return (
										<MDBox>
											<MDBox>
												<MDTypography variant="h6">{label}</MDTypography>
											</MDBox>
											<MDBox>
												<MDTypography variant="body2">{storage}</MDTypography>
											</MDBox>
										</MDBox>
									);
								}}
							>
								{mapMenuCollection(collections)}
							</Select>
						</FormControl>
					</MDBox>
				</MDBox>
				{/* Filtres */}
				<MDBox flex="1" display="flex" flexDirection="column" ml={1}>
					<MDTypography variant="h6">{t("CHARTS.STEPS.DATAS.filterDatas")}</MDTypography>
					<MDBox
						variant={!isStepValid ? "contained" : "gradient"}
						bgColor={!isStepValid ? "light" : "info"}
						p={1.5}
						borderRadius="md"
						style={{
							cursor: !isStepValid ? "" : "pointer"
						}}
						onClick={() => isStepValid && setOpenDrawer(true)}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<MDBox display="flex" flexDirection="column">
							<MDBox>
								<MDTypography variant="h6" color={!isStepValid ? "black" : "white"}>
									{t("CHARTS.STEPS.DATAS.showConditions")}
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDTypography variant="body2" color={!isStepValid ? "black" : "white"}>
									{t("CHARTS.STEPS.DATAS.addCondition")}
								</MDTypography>
							</MDBox>
						</MDBox>
						<MDBox mr={3}>
							<Icon fontSize="medium" color="white">
								open_in_new
							</Icon>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox flex="1" mt={1} className="displayLeftTab">
				<MDBox
					className="dialogContentChartEditor fullHeight"
					p={2}
					borderRadius="md"
					style={{
						overflow: "auto"
					}}
				>
					{(lod_.isNil(request.collection) ||
						lod_.isEmpty(request.collection) ||
						lod_.isNil(datasetChart)) && (
						<MDBox
							display="flex"
							justifyContent="center"
							alignItems="center"
							style={{ width: "100%" }}
						>
							<MDTypography variant="body1" color="textSecondary">
								{t("CHARTS.STEPS.DATAS.selectDatasource")}
							</MDTypography>
						</MDBox>
					)}
					{/* Datatable to display used datas */}
					{/* useMemo to reload the table only when collection or filters change */}
					{useMemo(
						() => (
							<MDBox flex="1">
								{request.collection && !lod_.isNil(datasetChart) && (
									<DefaultDataTable
										//
										list={datasetChart}
										pagination={{
											defaultEntrie: 20,
											entriesPerPage: [15, 20, 40, 50, 100]
										}}
										noMargin
										noHeader
										filters={filters}
										canSearch
										table={datasetChart.data}
										display={datasetChart?.request?.attributesDisplay || []}
										unknownColumns={datasetChart.unknownColumns === true} // Will fetch columns name dynamically
									/>
								)}
							</MDBox>
						),
						[request.collection, filters, datasetChart]
					)}
					{/* Drawer */}
					<Drawer
						anchor="right"
						open={openDrawer}
						onClose={() => closeDrawer()}
						style={{
							zIndex: 1300
						}}
						PaperProps={{
							sx: { width: "50%" }
						}}
					>
						<MDBox p={2} style={{ height: "100%" }}>
							<FiltersTable
								disabled={!isStepValid}
								filtersArray={filtersArray}
								onClose={closeDrawer}
								dictionary={dictionary}
								handleRemoveFilter={(name, path) => {
									handleRemoveFilterRow(name, path, setFiltersArray, handleChangeFilters);
								}}
								onChangeValue={filter => {
									handleChangeFilterRow(filter, setFiltersArray, handleChangeFilters);
								}}
								handleAddFilter={filter => {
									handleAddFilterRow(filter, dictionary, setFiltersArray, name => {
										return filtersArray.find(f => f.name === name);
									});
								}}
							/>
						</MDBox>
					</Drawer>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default DataChoiceStep;
