/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
import { useEffect, useRef, useState } from "react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// VerticalBarChart configurations
import configs from "components/Advanced/Charts/BarCharts/VerticalBarChart/configs";

import { Chart as ChartJS, registerables } from "chart.js";
import { Tab, Tabs } from "@mui/material";
import ListOptions from "components/Custom/ListOptions";

ChartJS.register(...registerables);

function VerticalBarChart({
	icon = { color: "info", component: "" },
	title = "",
	description = "",
	height = "19.125rem",
	chart,
	chartData,
	clickHandler = () => {}
}) {
	const chartRef = useRef(null);

	const { editMode = false } = chartData;

	const [tab, setTab] = useState(0);

	const chartDatasets = chartData.datasets
		? chartData.datasets.map(dataset => ({
				...dataset,
				weight: 5,
				borderWidth: 0,
				borderRadius: 4,
				backgroundColor: dataset.backgroundColor.length ? dataset.backgroundColor : "dark",
				fill: false,
				maxBarThickness: 35
			}))
		: [];

	const config = configs(
		chartRef,
		chartData.labels || [],
		chartDatasets,
		chartData.options?.valuePercentTab ? tab : null,
		clickHandler
	);

	const [options, setOptions] = useState(config.options);
	const [data, setData] = useState(config.data);

	useEffect(() => {
		let config = configs(
			chartRef,
			chartData.labels || [],
			chartDatasets,
			chartData.options?.valuePercentTab ? tab : null,
			clickHandler
		);

		setOptions(config.options);
		setData(config.data);
	}, [tab, chartData]);

	const renderChart = (
		<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
			<MDBox
				display="flex"
				justifyContent="space-between"
				px={description ? 1 : 0}
				pt={description ? 1 : 0}
			>
				<MDBox display="flex">
					{icon.component && (
						<MDBox
							width="4rem"
							height="4rem"
							bgColor={icon.color || "info"}
							variant="gradient"
							coloredShadow={icon.color || "info"}
							borderRadius="xl"
							display="flex"
							justifyContent="center"
							alignItems="center"
							color="white"
							mt={-5}
							mr={2}
						>
							<Icon fontSize="medium">{icon.component}</Icon>
						</MDBox>
					)}
					<MDBox mt={icon.component ? -2 : 0}>
						{title && <MDTypography variant="h6">{title}</MDTypography>}
						<MDBox mb={2}>
							<MDTypography component="div" variant="button" color="text">
								{description}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox mt={icon.component ? -2 : 0} display="flex" alignItems="center">
					{chartData.options?.valuePercentTab && (
						<MDBox mr={1}>
							<Tabs
								orientation="horizontal"
								value={tab}
								onChange={(e, v) => {
									setTab(v);
								}}
							>
								<Tab
									sx={{
										fontSize: "0.75rem"
									}}
									label="Valeurs"
									icon={
										<Icon fontSize="small" sx={{ mt: -0.25 }}>
											looks_one
										</Icon>
									}
								/>
								<Tab
									sx={{
										fontSize: "0.75rem"
									}}
									label="Pourcentage"
									icon={
										<Icon fontSize="small" sx={{ mt: -0.25 }}>
											percent
										</Icon>
									}
								/>
							</Tabs>
						</MDBox>
					)}
					{!editMode && <ListOptions chart={chart} options={chartData.options ?? {}} />}
				</MDBox>
			</MDBox>
			<MDBox height={height}>
				<Bar ref={chartRef} data={chartData} options={options} />
			</MDBox>
		</MDBox>
	);

	return <Card>{renderChart}</Card>;
}

export default VerticalBarChart;
